import { ActiveComponents, useCciMainContext } from '../../../../CCI_Main'
import { ConfigureField } from './ConfigureField'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import React, { FC } from 'react'
import ViewField from '../ChecklistTab/ViewField'

// components

export const CCI_RightPanel_ChecklistGptTab: FC = () => {
  const { activeComponent } = useCciMainContext()
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const hasLimitedEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD_LIMITED })

  return (
    <>
      {(!activeComponent || activeComponent === ActiveComponents.DELETE_FIELD || activeComponent === ActiveComponents.EDIT_FIELD) && (
        <>{hasFullEditAccess || hasLimitedEditAccess ? <ConfigureField /> : <ViewField />}</>
      )}

      {(activeComponent === ActiveComponents.CREATE_FIELD || activeComponent === ActiveComponents.EDIT_DOCUMENT_TYPES) && <ConfigureField />}
    </>
  )
}
