import { AppBar, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { DOCUMENT_SELECTOR_HEIGHT } from '../DocumentSelector'
import { DataPointDataTable } from '../../../graphql/codegen/schemas'
import { ExportToCsv } from 'export-to-csv'
import { Opening, useOpening } from '@hoologic/use-opening'
import { RESIZABLE_MINIMUM_HEIGHT, Resizable } from '../../Resizable'
import { SlideUpTransition } from '../../Transition'
import { Toast } from '../../Toast'
import { Z_INDEX_OVERLAY } from '../../../utils/styleUtils'
import { captureError } from '../../../utils/sentry'
import { useContextInit } from '../../../hooks/useContextInit'
import { useDataPointsQuery, useEditDataTableDataPointValueMutation } from '../../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import { useRefCallback } from '../../../hooks/useRefCallback'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DataGrid, { Column, TextEditor } from 'react-data-grid'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import Loader from '../../Loader'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useMemo, useState } from 'react'
import copy from 'copy-to-clipboard'
import css from './style.module.scss'

// types

type _DataTableContext = {
  columns: Column<any>[]
  handleCopy: () => void
  handleDownload: () => void
  handleRowHeight: ({ row }: any) => 40 | 60
  handleRowsChange: (rows: _Row[]) => void
  rows: _Row[]
  setRows: Dispatch<SetStateAction<_Row[]>>
}

type _AddRowProps = { color?: 'default' | 'inherit'; setRows: Dispatch<SetStateAction<_Row[]>> }
type _CopyTableProps = { color?: 'default' | 'inherit' }
type _DataTableDialogProps = { isLoading: boolean; name: string; opening: Opening }
type _DataTableProps = { dealIsFinalized: boolean; setActiveTableId: Dispatch<SetStateAction<string | null>>; tableId: string }
type _DownloadCsvProps = { color?: 'default' | 'inherit' }
type _ExpandViewProps = { opening: Opening }
type _Row = { [k: string]: number | string; id: number }

// constants

const BIG_ROW_HEIGHT = 60
const DEFAULT_ROW_HEIGHT = 40
const MAXIMUM_TEXT_CHARS = 41

// context

const DataTableContext = createContext<_DataTableContext | null>(null)

// functions

export const copyDataTable = (dataTable: DataPointDataTable, setToast: Dispatch<SetStateAction<string>>) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  let counter = 1

  copy(
    [
      ...(columns ? [columns.map(column => column || `<Untitled ${counter++}>`)] : []),
      ...(columns ? rows : [rows[0].map(row => row || `<Untitled ${counter++}>`), ...rows.slice(1)])
    ]
      .map(row => row.join('\t'))
      .join('\n'),
    {
      format: 'text/plain'
    }
  )

  setToast('Table copied to clipboard.')
}

export const downloadDataTableCsvFile = (dataTable: DataPointDataTable) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  let counter = 1

  const options = {
    filename: 'table',
    ...(columns && { headers: columns.map(column => `"${column || `<Untitled ${counter++}>`}"`) }),
    showLabels: true
  }

  new ExportToCsv(options).generateCsv(columns ? rows : [rows[0].map(row => row || `<Untitled ${counter++}>`), ...rows.slice(1)])
}

const getDataTableColumnsAndRows = (dataTable: DataPointDataTable): [string[] | undefined, string[][]] => {
  const columns = dataTable.columns as string[] | undefined
  const rows = (JSON.parse(dataTable.rows!) as string[]).map(row => Object.values(row))

  return [columns, rows]
}

// hooks

const useDataTableContext = () => useContextInit(DataTableContext)

// components

const AddRow: FC<_AddRowProps> = ({ color = 'default', setRows }) => (
  <Tooltip arrow title="Add row">
    <IconButton color={color} onClick={() => setRows(previous => [...previous, { id: (previous.at(-1)?.id ?? -1) + 1 }])} size="small">
      <AddIcon />
    </IconButton>
  </Tooltip>
)

const CopyTable: FC<_CopyTableProps> = ({ color = 'default' }) => {
  const { handleCopy } = useDataTableContext()

  return (
    <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Copy table">
      <IconButton color={color} onClick={handleCopy} size="small">
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  )
}

export const DataTable: FC<_DataTableProps> = ({ dealIsFinalized, setActiveTableId, tableId }) => {
  const opening = useOpening()
  const [absoluteHeight, setAbsoluteHeight] = useState<number>(RESIZABLE_MINIMUM_HEIGHT)
  const [rows, setRows] = useState<_Row[]>([])
  const [toast, setToast] = useState('')
  const { dealId } = useParams<{ dealId?: string }>()

  const { data, loading: areDataPointsLoading } = useDataPointsQuery({
    variables: { id: tableId },
    onCompleted: ({ data_points }) => {
      const { rows } = data_points?.edges[0]?.node?.value_data_table || {}
      if (rows) {
        try {
          return setRows(JSON.parse(rows))
        } catch (error) {
          captureError(error)
        }
      }
      const fallbackRows = Array(10)
        .fill(true)
        .map((_, idx) => ({ id: idx }))
      return setRows(fallbackRows)
    }
  })

  const [editDataTableDataPointValue, { loading: isLoading }] = useEditDataTableDataPointValueMutation({ onCompleted: () => setToast('Table saved.') })

  const dataTable = useMemo(() => data?.data_points?.edges[0]?.node?.value_data_table, [data?.data_points?.edges])

  const columns = useMemo<Column<any>[]>(() => {
    const formattedColumns = []

    for (const name of dataTable?.columns || []) {
      if (name) {
        const displayName = name.includes('Unnamed') ? '' : name
        const formattedName = name.toLowerCase().split(' ').join('_')

        formattedColumns.push({
          cellClass: css.cell,
          editor: TextEditor,
          headerRenderer: () => (
            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={displayName}>
              {displayName}
            </Box>
          ),
          key: formattedName,
          name: displayName
        })
      }
    }

    return formattedColumns
  }, [dataTable])

  const handleCopy = useCallback(() => {
    if (dataTable) {
      copyDataTable(dataTable, setToast)
    }
  }, [dataTable])

  const handleDownload = useCallback(() => {
    if (dataTable) {
      downloadDataTableCsvFile(dataTable)
    }
  }, [dataTable])

  const handleRowHeight = useCallback(({ row }) => (row?.item_name?.length > MAXIMUM_TEXT_CHARS ? BIG_ROW_HEIGHT : DEFAULT_ROW_HEIGHT), [])

  const handleSave = useCallback(
    (rows: _Row[]) => {
      const dataPointFieldId = data?.data_points?.edges[0]?.node?.data_point_field?.id!
      const documentId = data?.data_points?.edges[0]?.node?.document?.id || ''

      editDataTableDataPointValue({
        variables: {
          dataPointFieldId,

          // scenario #1: user edits document data table on document page: send document_id
          // scenario #2: user edits deal data table on deal page: send deal_id
          // scenario #3: user edits document data table on deal page: send document_id

          dealId: documentId ? '' : dealId!,
          documentId,
          tableColumns: dataTable?.columns!,
          tableRows: JSON.stringify(rows)
        }
      })
    },
    [data?.data_points?.edges, dataTable?.columns, dealId, editDataTableDataPointValue]
  )

  const handleRowsChange = useCallback(
    (rows: _Row[]) => {
      if (!dealIsFinalized) {
        handleSave(rows)
        setRows(rows)
      }
    },
    [dealIsFinalized, handleSave]
  )

  const context = useMemo<_DataTableContext>(
    () => ({ columns, handleCopy, handleDownload, handleRowHeight, handleRowsChange, rows, setRows }),
    [columns, handleCopy, handleDownload, handleRowHeight, handleRowsChange, rows]
  )

  const name = useMemo(() => data?.data_points?.edges[0]?.node?.data_point_field?.name || '', [data?.data_points?.edges])

  return (
    <DataTableContext.Provider value={context}>
      <Resizable height={absoluteHeight} setHeight={setAbsoluteHeight}>
        <Box sx={{ height: absoluteHeight, pb: dealId ? `${DOCUMENT_SELECTOR_HEIGHT}px` : undefined }}>
          {areDataPointsLoading || !rows ? (
            <Loader />
          ) : (
            <>
              <Box alignItems="center" bgcolor="#f3f4f8" display="flex" flexDirection="row-reverse" justifyContent="space-between" padding="8px 4px 4px">
                <Box display="flex" mr={0.5}>
                  <AddRow setRows={setRows} />

                  <Tooltip arrow title="Close table">
                    <IconButton onClick={() => setActiveTableId(null)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                {isLoading && <CircularProgress size={24} />}

                {Boolean(dataTable) && (
                  <Box display="flex" gap={0.5} ml={0.5}>
                    <CopyTable />

                    <DownloadCsv />

                    <ExpandView opening={opening} />
                  </Box>
                )}
              </Box>

              <DataGrid
                className="rdg-light"
                columns={columns}
                defaultColumnOptions={{ resizable: true }}
                onRowsChange={handleRowsChange}
                rowHeight={handleRowHeight}
                rows={rows}
                style={{ height: absoluteHeight }}
              />

              <DataTableDialog isLoading={isLoading} name={name} opening={opening} />
            </>
          )}
        </Box>
      </Resizable>

      <Toast message={toast} setMessage={setToast} />
    </DataTableContext.Provider>
  )
}

const DataTableDialog: FC<_DataTableDialogProps> = ({ isLoading, name, opening }) => {
  const { columns, handleRowHeight, handleRowsChange, rows, setRows } = useDataTableContext()

  return (
    <Dialog
      PaperProps={{ sx: { backgroundColor: '#e7ebf0' } }}
      TransitionComponent={SlideUpTransition}
      fullScreen
      onClose={opening.close}
      open={opening.isOpen}
      sx={{ zIndex: Z_INDEX_OVERLAY }}
    >
      <AppBar sx={{ mb: 1, position: 'relative' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
            <Typography component="h2" sx={{ mr: 2 }} variant="h4">
              {name}
            </Typography>

            <CopyTable color="inherit" />

            <DownloadCsv color="inherit" />
          </Box>

          {isLoading && <CircularProgress color="inherit" size={24} />}

          <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
            <AddRow color="inherit" setRows={setRows} />

            <Button color="inherit" onClick={opening.close} variant="outlined">
              Close
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <DialogContent sx={{ p: 2.5, pt: 2 }}>
        <DataGrid
          className="rdg-light"
          columns={columns}
          defaultColumnOptions={{ resizable: true }}
          onRowsChange={handleRowsChange}
          rowHeight={handleRowHeight}
          rows={rows}
          style={{ height: '100%' }}
        />
      </DialogContent>
    </Dialog>
  )
}

const DownloadCsv: FC<_DownloadCsvProps> = ({ color = 'default' }) => {
  const { handleDownload } = useDataTableContext()

  return (
    <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Download CSV">
      <IconButton color={color} onClick={handleDownload} size="small">
        <FileDownloadOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

const ExpandView: FC<_ExpandViewProps> = ({ opening }) => {
  const [element, refCallback] = useRefCallback()

  const handleExpandView = useCallback(() => {
    element?.blur()

    opening.open()
  }, [element, opening])

  return (
    <Tooltip arrow title="Expand view">
      <IconButton onClick={handleExpandView} ref={refCallback} size="small">
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  )
}
