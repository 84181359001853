import { Box, TextField, Typography } from '@mui/material'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useFieldValuesContext } from '../FieldValuesProvider'
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import css from './style.module.scss'

// constants

const LABEL = 'Automation Tag'

const NEW_NEUTRAL_TAG_FIELD = 'new_neutral_tag'

const OLD_NEUTRAL_TAG_FIELD = 'old_neutral_tag'

// components

export const AutomationTagInput: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { updateFieldValue } = useFieldValuesContext()

  const initialValue = useMemo(() => selectedItem?.external_mapping?.[0] || '', [selectedItem?.external_mapping])

  const [value, setValue] = useState(initialValue)

  const handleBlur = useCallback(() => {
    if (!value.trim()) {
      setValue(initialValue)

      updateFieldValue(NEW_NEUTRAL_TAG_FIELD, null)
      updateFieldValue(OLD_NEUTRAL_TAG_FIELD, null)
    }
  }, [initialValue, value, updateFieldValue])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      setValue(value)

      const trimmedValue = value.trim()

      updateFieldValue(NEW_NEUTRAL_TAG_FIELD, trimmedValue === initialValue ? null : trimmedValue)
      updateFieldValue(OLD_NEUTRAL_TAG_FIELD, trimmedValue === initialValue ? null : initialValue)
    },
    [initialValue, updateFieldValue]
  )

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="automation-tag-input" sx={{ flexShrink: 0, fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <TextField
        InputProps={{ sx: { fontSize: 14 } }}
        autoComplete="off"
        fullWidth
        id="automation-tag-input"
        onBlur={handleBlur}
        onChange={handleChange}
        size="small"
        value={value}
        variant="outlined"
      />
    </Box>
  )
}
