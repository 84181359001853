import {
  CalculationSourceLabels,
  CalculationSources,
  ExternalSourceLabels,
  ExternalSources,
  SourceLabels,
  Sources,
  _Source
} from '../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/CreateInputs/SourceInput'
import { CollisionTypeLabels, CollisionTypes } from '../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/CollisionTypeInput'
import {
  DataTableMatchRuleLabels,
  DataTableMatchRules,
  MatchRuleLabels,
  MatchRules
} from '../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/MatchRule'
import {
  ExtractionFieldTypeLabels,
  ExtractionFieldTypes
} from '../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { FieldTypeLabels, FieldTypes } from '../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/CreateInputs/FieldTypeInput'
import { cloneDeep } from 'lodash'

// types

export enum OnOffLabels {
  OFF = 'Off',
  ON = 'On'
}

enum StatusLabels {
  DRAFT = 'Draft',
  IN_REVIEW = 'In review',
  PUBLISHED = 'Published',
  PUBLISH_PENDING = 'Publish Pending'
}

export enum Statuses {
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  PUBLISHED = 'PUBLISHED',
  PUBLISH_PENDING = 'PUBLISH_PENDING'
}

enum SubstatusLabels {
  ARCHIVED = 'Archived',
  PENDING_SAMPLES_GATHERING = 'Pending Samples Gathering',
  PUBLISHED = 'Published',
  PUBLISHING_FAILED = 'Publishing Failed',
  PUBLISHING_IN_PROGRESS = 'Publishing In Progress',
  REVIEW_COMPLETED = 'Review Completed',
  SAMPLES_READY_FOR_REVIEW = 'Samples Ready For Review',
  SAMPLES_REVIEW_IN_PROGRESS = 'Samples Review In Progress'
}

export enum Substatuses {
  ARCHIVED = 'ARCHIVED',
  PENDING_SAMPLES_GATHERING = 'PENDING_SAMPLES_GATHERING',
  PUBLISHED = 'PUBLISHED',
  PUBLISHING_FAILED = 'PUBLISHING_FAILED',
  PUBLISHING_IN_PROGRESS = 'PUBLISHING_IN_PROGRESS',
  REVIEW_COMPLETED = 'REVIEW_COMPLETED',
  SAMPLES_READY_FOR_REVIEW = 'SAMPLES_READY_FOR_REVIEW',
  SAMPLES_REVIEW_IN_PROGRESS = 'SAMPLES_REVIEW_IN_PROGRESS'
}

// constants

const COLLISION_TYPE_LABELS = createValueLabelMap(CollisionTypes, CollisionTypeLabels)

const DATA_TABLE_MATCH_RULE_LABELS = createValueLabelMap(DataTableMatchRules, DataTableMatchRuleLabels)

export const DELETE = '@@_DELETE_THIS_ITEM'

export const EXTERNAL_SOURCE_LABELS = createValueLabelMap(ExternalSources, ExternalSourceLabels)

const EXTRACTION_FIELD_TYPE_LABELS = createValueLabelMap(ExtractionFieldTypes, ExtractionFieldTypeLabels)

export const FIELD_TYPE_LABEL_MAP = createValueLabelMap(FieldTypes, FieldTypeLabels)

const MATCH_RULE_LABELS = createValueLabelMap(MatchRules, MatchRuleLabels)

const SOURCE_LABELS = new Map([
  ...Array.from(createValueLabelMap(CalculationSources, CalculationSourceLabels)),
  ...Array.from(createValueLabelMap(Sources, SourceLabels)),
  ...Array.from(EXTERNAL_SOURCE_LABELS)
])

export const STATUS_VALUE_LABEL_MAP = createValueLabelMap(Statuses, StatusLabels)
export const SUBSTATUS_VALUE_LABEL_MAP = createValueLabelMap(Substatuses, SubstatusLabels)

// functions

export function createValueLabelMap<T extends string>(values: { [key in T]: string | number }, labels: { [key in T]: string }): Map<string | number, string> {
  return (Object.keys(values) as Array<keyof typeof values>).reduce(
    (map, key) => (typeof values[key] === 'string' ? map.set(values[key], labels[key]) : map),
    new Map<string | number, string>()
  )
}

export const getCollisionTypeLabel = (value?: string) => (value ? COLLISION_TYPE_LABELS.get(value as CollisionTypes) || value : 'Select collision type')

export const getDataTableMatchRuleLabel = (value?: string) =>
  value ? DATA_TABLE_MATCH_RULE_LABELS.get(value as DataTableMatchRules) || value : 'Select a match rule'

export const getExtractionFieldTypeLabel = (value?: ExtractionFieldTypes) =>
  value ? EXTRACTION_FIELD_TYPE_LABELS.get(value) || value : 'Select extraction type'

export const getFieldTypeLabel = (selectedValue?: string, currentValue?: string) =>
  selectedValue
    ? FIELD_TYPE_LABEL_MAP.get(selectedValue as FieldTypes) || selectedValue
    : currentValue
    ? FIELD_TYPE_LABEL_MAP.get(currentValue as FieldTypes) || currentValue
    : 'Select a field type'

export const getMatchRuleLabel = (value?: string) => (value ? MATCH_RULE_LABELS.get(value as MatchRules) || value : 'Select a match rule')

export const getSourceLabel = (value?: string) => (value ? SOURCE_LABELS.get(value as _Source) || value : 'Select source')

export const reorderList = <T>(list: T[], startIndex: number, endIndex: number) => {
  const reorderedList = cloneDeep(list)

  const [removed] = reorderedList.splice(startIndex, 1)

  reorderedList.splice(endIndex, 0, removed)

  return reorderedList
}
