import { TenantTypes, useIsProductionTenant, useTenantType } from './useTenantType'
import { useHasChecklistGptAccess } from './useUserAccess'

// hooks

export const useIsExtractionRerunEnabled = () => {
  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const tenantType = useTenantType()

  const isGoldTenant = tenantType === TenantTypes.GOLD
  const isPOCTenant = tenantType === TenantTypes.POC
  const isProductionTenant = useIsProductionTenant()

  return hasChecklistGptAccess && !isGoldTenant && !isPOCTenant && !isProductionTenant
}
