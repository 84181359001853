import { SpreadsheetFileViewer } from './SpreadsheetFileViewer'
import { captureError } from '../../utils/sentry'
import { getSpreadsheetDocument } from '../../utils/documentApiUtils'
import { useAppContext } from '../../app'
import Loader from '../Loader/Loader'
import React, { FC, useEffect, useState } from 'react'

// types

type SpreadsheetDocumentWrapperProps = { documentId: string; documentName: string }

// components

export const SpreadsheetDocumentWrapper: FC<SpreadsheetDocumentWrapperProps> = ({ documentId, documentName }) => {
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  useEffect(() => {
    ;(async () => {
      if (!documentId) return

      try {
        const spreadsheetDocument = await getSpreadsheetDocument(documentId, documentName)

        setFile(spreadsheetDocument)
      } catch (error: unknown) {
        captureError(error)

        const { message, stack } = error as Error

        setErrorMessage(message)
        setExtendedErrorMessage(stack || message)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [documentId, documentName, setErrorMessage, setExtendedErrorMessage])

  return isLoading ? (
    <Loader style={{ borderTop: '1px solid #e0e0e0', background: 'white', height: '100%' }} />
  ) : file ? (
    <SpreadsheetFileViewer file={file} id={documentId} />
  ) : null
}
