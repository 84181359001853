import { Box, Divider, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material'
import { grey, yellow } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { startSheetOperations, stopSheetOperations } from './utils/sheetConfig'
import CloseIcon from '@mui/icons-material/Close'
import GC from '@mescius/spread-sheets'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { FC, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'

// types

type _HighlightedCell = { columnIndex: number; originalStyle: GC.Spread.Sheets.Style | null; rowIndex: number }

type _SearchProps = { workbook: GC.Spread.Sheets.Workbook | null }

// enums

enum SearchDirections {
  Next,
  Previous
}

// constants

const ANIMATION_DURATION = 150

const SEARCH_BAR_SX = {
  alignItems: 'center',
  background: 'white',
  border: `1px solid ${grey[300]}`,
  borderTop: 'none',
  borderRadius: '0 0 4px 4px',
  display: 'flex',
  padding: 1,
  position: 'absolute',
  right: 8,
  top: 0,
  transition: `transform ${ANIMATION_DURATION}ms ease-in-out`,
  width: 340,
  zIndex: 2
}

const SEARCH_HIGHLIGHT_COLOR = `${yellow.A100}99` // 60% opacity.

// components

export const SearchBar: FC<_SearchProps> = ({ workbook }) => {
  const [highlightedCells, setHighlightedCells] = useState<_HighlightedCell[]>([])
  const [isVisible, setIsVisible] = useState(false)
  const [searchResults, setSearchResults] = useState<{ currentMatchNumber: number; totalMatches: number } | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [shouldRender, setShouldRender] = useState(false)

  const animationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  // functions

  const clearHighlights = useCallback(() => {
    if (isEmpty(highlightedCells) || !workbook) return

    const sheet = workbook.getActiveSheet()

    stopSheetOperations(sheet)

    // Restore original style or use empty style if none existed.
    highlightedCells.forEach(cell => sheet.setStyle(cell.rowIndex, cell.columnIndex, cell.originalStyle ?? new GC.Spread.Sheets.Style()))

    startSheetOperations(sheet)

    setHighlightedCells([])
  }, [highlightedCells, workbook])

  const findAllMatches = useCallback(() => {
    if (!searchValue || !workbook) return []

    const matches: { columnIndex: number; rowIndex: number }[] = []
    const sheet = workbook.getActiveSheet()

    const searchCondition = new GC.Spread.Sheets.Search.SearchCondition()
    searchCondition.columnEnd = sheet.getColumnCount()
    searchCondition.columnStart = -1
    searchCondition.endSheetIndex = workbook.getActiveSheetIndex()
    searchCondition.rowEnd = sheet.getRowCount()
    searchCondition.rowStart = -1
    searchCondition.searchFlags = GC.Spread.Sheets.Search.SearchFlags.ignoreCase
    searchCondition.searchString = searchValue
    searchCondition.searchTarget = GC.Spread.Sheets.Search.SearchFoundFlags.cellText
    searchCondition.startSheetIndex = workbook.getActiveSheetIndex()

    let result = workbook.search(searchCondition)

    while (result && result.searchFoundFlag !== GC.Spread.Sheets.Search.SearchFoundFlags.none) {
      const { foundColumnIndex, foundRowIndex } = result

      matches.push({ columnIndex: foundColumnIndex, rowIndex: foundRowIndex })

      searchCondition.columnStart = foundColumnIndex + 1
      searchCondition.rowStart = foundRowIndex

      if (searchCondition.columnStart >= sheet.getColumnCount()) {
        searchCondition.columnStart = 0
        searchCondition.rowStart += 1
      }

      result = workbook.search(searchCondition)
    }

    return matches
  }, [searchValue, workbook])

  const findMatch = (direction: SearchDirections) => {
    if (!searchValue || !workbook) return

    const matches = findAllMatches()

    if (isEmpty(matches)) {
      clearHighlights()
      setSearchResults(null)

      return
    }

    highlightMatches(matches)

    const sheet = workbook.getActiveSheet()
    const activeRowIndex = sheet.getActiveRowIndex()
    const activeColumnIndex = sheet.getActiveColumnIndex()

    let currentIndex = matches.findIndex(match => match.rowIndex === activeRowIndex && match.columnIndex === activeColumnIndex)

    if (direction === SearchDirections.Next) {
      currentIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % matches.length
    } else {
      currentIndex = currentIndex === -1 ? matches.length - 1 : (currentIndex - 1 + matches.length) % matches.length
    }

    const targetMatch = matches[currentIndex]

    sheet.setActiveCell(targetMatch.rowIndex, targetMatch.columnIndex)

    setSearchResults({ currentMatchNumber: currentIndex + 1, totalMatches: matches.length })

    focusOnCell(sheet, targetMatch.rowIndex, targetMatch.columnIndex)
  }

  const focusOnCell = useCallback((sheet: GC.Spread.Sheets.Worksheet, rowIndex: number, columnIndex: number) => {
    const isOutsideViewport =
      columnIndex < sheet.getViewportLeftColumn(1) ||
      columnIndex > sheet.getViewportRightColumn(1) ||
      rowIndex < sheet.getViewportTopRow(1) ||
      rowIndex > sheet.getViewportBottomRow(1)

    if (isOutsideViewport) {
      sheet.showCell(rowIndex, columnIndex, GC.Spread.Sheets.VerticalPosition.center, GC.Spread.Sheets.HorizontalPosition.center)
    } else {
      sheet.repaint()
    }
  }, [])

  const handleSearchInputKeyDown = (event: ReactKeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      unmountSearchBar()
    } else if (event.key === 'Enter') {
      event.preventDefault()

      event.shiftKey ? findMatch(SearchDirections.Previous) : findMatch(SearchDirections.Next)
    }
  }

  const highlightMatches = useCallback(
    (matches: { columnIndex: number; rowIndex: number }[]) => {
      if (isEmpty(matches) || !workbook) return

      const existingHighlightMap = new Map(highlightedCells.map(cell => [`${cell.columnIndex}${cell.rowIndex}`, cell]))
      const newHighlightMap = new Map(matches.map(cell => [`${cell.columnIndex}${cell.rowIndex}`, cell]))

      const cellsToHighlight = matches.filter(cell => !existingHighlightMap.has(`${cell.columnIndex}${cell.rowIndex}`))
      const cellsToUnhighlight = highlightedCells.filter(cell => !newHighlightMap.has(`${cell.columnIndex}${cell.rowIndex}`))

      if (isEmpty(cellsToUnhighlight) && isEmpty(cellsToHighlight)) return

      const sheet = workbook.getActiveSheet()

      stopSheetOperations(sheet)

      cellsToUnhighlight.forEach(cell => sheet.setStyle(cell.rowIndex, cell.columnIndex, cell.originalStyle ?? new GC.Spread.Sheets.Style()))

      const newHighlightedCells = [...highlightedCells.filter(cell => newHighlightMap.has(`${cell.columnIndex}${cell.rowIndex}`))]

      cellsToHighlight.forEach(cell => {
        const originalStyle = sheet.getStyle(cell.rowIndex, cell.columnIndex)?.clone() || null
        const newStyle = originalStyle?.clone() || new GC.Spread.Sheets.Style()

        newStyle.backColor = SEARCH_HIGHLIGHT_COLOR

        sheet.setStyle(cell.rowIndex, cell.columnIndex, newStyle)

        newHighlightedCells.push({ columnIndex: cell.columnIndex, originalStyle, rowIndex: cell.rowIndex })
      })

      startSheetOperations(sheet)

      setHighlightedCells(newHighlightedCells)
    },
    [highlightedCells, workbook]
  )

  const mountSearchBar = useCallback(() => {
    // Add component to the DOM first, then animate it into view on the next render cycle.
    setShouldRender(true)

    if (animationTimeoutRef.current !== null) {
      clearTimeout(animationTimeoutRef.current)

      animationTimeoutRef.current = null
    }

    requestAnimationFrame(() => setIsVisible(true))
  }, [])

  const unmountSearchBar = useCallback(() => {
    setSearchResults(null)
    setSearchValue('')

    // Hide the component first, then remove it from the DOM after the animation completes.
    setIsVisible(false)

    if (animationTimeoutRef.current !== null) clearTimeout(animationTimeoutRef.current)

    animationTimeoutRef.current = setTimeout(() => {
      animationTimeoutRef.current = null

      setShouldRender(false)
    }, ANIMATION_DURATION)
  }, [])

  const updateSearchResults = useCallback(() => {
    const matches = findAllMatches()

    if (isEmpty(matches) || !searchValue || !workbook) {
      clearHighlights()
      setSearchResults(null)

      return
    }

    highlightMatches(matches)

    const sheet = workbook.getActiveSheet()
    const activeRowIndex = sheet.getActiveRowIndex()
    const activeColumnIndex = sheet.getActiveColumnIndex()
    const currentMatchIndex = matches.findIndex(match => match.rowIndex === activeRowIndex && match.columnIndex === activeColumnIndex)

    if (currentMatchIndex !== -1) {
      // Current cell is a match – keep focus here.
      setSearchResults({ currentMatchNumber: currentMatchIndex + 1, totalMatches: matches.length })

      focusOnCell(sheet, activeRowIndex, activeColumnIndex)
    } else if (matches.length > 0) {
      // Current cell is not a match – move to the first match.
      const firstMatch = matches[0]

      sheet.setActiveCell(firstMatch.rowIndex, firstMatch.columnIndex)

      setSearchResults({ currentMatchNumber: 1, totalMatches: matches.length })

      focusOnCell(sheet, firstMatch.rowIndex, firstMatch.columnIndex)
    }
  }, [clearHighlights, focusOnCell, findAllMatches, highlightMatches, searchValue, workbook])

  // effects

  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current !== null) clearTimeout(animationTimeoutRef.current)
    }
  }, [])

  useEffect(() => {
    updateSearchResults()

    if (searchValue === '') clearHighlights()
  }, [clearHighlights, searchValue, updateSearchResults])

  useEffect(() => {
    if (isVisible) {
      workbook?.focus(false)

      searchInputRef.current?.focus()
    } else {
      workbook?.focus(true)

      clearHighlights()
    }
  }, [clearHighlights, isVisible, workbook])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()

        isVisible ? searchInputRef.current?.focus() : mountSearchBar()
      }

      if (event.key === 'Escape') unmountSearchBar()
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isVisible, mountSearchBar, unmountSearchBar])

  if (!shouldRender) return null

  // render

  return (
    <Box sx={{ ...SEARCH_BAR_SX, transform: isVisible ? 'translateY(0)' : 'translateY(-58px)' }}>
      <Box sx={{ position: 'relative', flex: 1 }}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={{ color: theme => theme.palette.text.secondary, fontSize: '0.75rem', visibility: searchValue ? 'visible' : 'hidden' }}>
                  {searchResults ? `${searchResults.currentMatchNumber} of ${searchResults.totalMatches}` : searchValue ? '0 of 0' : ' '}
                </Typography>
              </InputAdornment>
            )
          }}
          autoComplete="off"
          autoFocus={isVisible}
          inputRef={searchInputRef}
          onChange={event => setSearchValue(event.target.value)}
          onFocus={() => workbook?.focus(false)}
          onKeyDown={handleSearchInputKeyDown}
          placeholder="Search…"
          size="small"
          sx={{ width: '100%', '& .MuiOutlinedInput-root': { backgroundColor: 'white' } }}
          value={searchValue}
        />
      </Box>

      <Box sx={{ display: 'flex', ml: 1 }}>
        <Tooltip arrow placement="top" title={searchResults ? 'Previous match' : ''}>
          <span>
            <IconButton disabled={!searchResults} onClick={() => findMatch(SearchDirections.Previous)} size="small">
              <KeyboardArrowUpIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip arrow placement="top" title={searchResults ? 'Next match' : ''}>
          <span>
            <IconButton disabled={!searchResults} onClick={() => findMatch(SearchDirections.Next)} size="small">
              <KeyboardArrowDownIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>

        <Divider flexItem orientation="vertical" sx={{ m: '2px 8px' }} variant="middle" />

        <Tooltip arrow placement="top" title="Close search">
          <IconButton aria-label="Close search" onClick={unmountSearchBar} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
