import { Typography } from '@mui/material'
import React, { FC, useMemo } from 'react'

// types

enum Visibilities {
  HIDE = 'Hide',
  HIDE_IF_EMPTY = 'Hide if empty',
  ONLY_SHOW_HIGHLIGHTS = 'Only show highlights',
  SHOW = 'Show'
}

type _VisibilityCellProps = { displayAnnotations: boolean; displayIfEmpty: boolean; displayOnChecklist: boolean }

// components

export const VisibilityCell: FC<_VisibilityCellProps> = ({ displayAnnotations, displayIfEmpty, displayOnChecklist }) => {
  const visibility = useMemo(() => {
    if (displayOnChecklist) {
      return displayIfEmpty ? Visibilities.SHOW : Visibilities.HIDE_IF_EMPTY
    } else {
      return displayAnnotations ? Visibilities.ONLY_SHOW_HIGHLIGHTS : Visibilities.HIDE
    }
  }, [displayAnnotations, displayIfEmpty, displayOnChecklist])

  return (
    <Typography noWrap variant="body2">
      {visibility}
    </Typography>
  )
}
