import { ActiveComponents, useCciMainContext } from '../../CCI_Main'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import {
  CciChecklistValidationError,
  CciChecklistValidationResponse,
  useImportCciChecklistMutation,
  useValidateCciChecklistMutation
} from '../../../../app/restApi'
import { Features, Permissions, useUserAccess } from '../../../../hooks/useUserAccess'
import { Z_INDEX_MODAL } from '../../../../utils/styleUtils'
import { grey } from '@mui/material/colors'
import { useOpening } from '@hoologic/use-opening'
import Dropzone from '../../../../components/Dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, useCallback, useState } from 'react'

// types

type _FieldImportProps = { onImportSuccess?: () => void }

type _FieldImportDialogContentProps = {
  file: File | null
  isLoading: boolean
  onClose: () => void
  onDrop: (files: File[]) => void
  onSkipReviewChange: (checked: boolean) => void
  onSubmit: () => void
  shouldSkipReview: boolean
  validationError: CciChecklistValidationResponse | null
}

type _LoadingDialogContentProps = { isValidating: boolean }

type _ValidationDialogContentProps = { onCancel: () => void; onConfirm: () => void; validationError: CciChecklistValidationResponse }

// enums

enum DialogViews {
  IMPORT,
  LOADING,
  VALIDATION
}

// components

export const FieldImport: FC<_FieldImportProps> = ({ onImportSuccess }) => {
  const { fieldOverlay, setActiveComponent } = useCciMainContext()

  const [dialogView, setDialogView] = useState<DialogViews>(DialogViews.IMPORT)
  const [file, setFile] = useState<File | null>(null)
  const [shouldSkipReview, setShouldSkipReview] = useState(false)
  const [validationError, setValidationError] = useState<CciChecklistValidationResponse | null>(null)
  const [showDataValidationDialog, setShowDataValidationDialog] = useState(false)

  const [importChecklist, { isLoading: isImporting }] = useImportCciChecklistMutation()
  const [validateChecklist, { isLoading: isValidating }] = useValidateCciChecklistMutation()

  const hasChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })
  const hasLimitedChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD_LIMITED })
  const hasCreateAccess = hasChecklistCreateAccess || hasLimitedChecklistCreateAccess

  const isLoading = isValidating || isImporting

  const opening = useOpening()

  // functions

  const handleAddField = useCallback(() => {
    fieldOverlay.open()

    setActiveComponent(ActiveComponents.CREATE_FIELD)
  }, [fieldOverlay, setActiveComponent])

  const handleDialogClose = () => {
    opening.close()

    setFile(null)
    setShouldSkipReview(false)
    setValidationError(null)
  }

  const handleDialogOpen = () => {
    opening.open()

    setDialogView(DialogViews.IMPORT)
  }

  const handleImportWithUnsupportedFields = async () => {
    if (!file) return

    setDialogView(DialogViews.LOADING)

    const importFormData = new FormData()
    importFormData.append('file', file)
    importFormData.append('skip_review_and_publish', shouldSkipReview.toString())

    try {
      const importResponse = await importChecklist(importFormData).unwrap()

      if (importResponse.status === 'success') onImportSuccess?.()
    } catch (error: any) {
      setValidationError(error.data)
      setDialogView(DialogViews.IMPORT)
    }

    setShowDataValidationDialog(false)
  }

  const handleSubmit = async () => {
    if (!file) return

    setDialogView(DialogViews.LOADING)

    try {
      const formData = new FormData()
      formData.append('file', file)

      const validationResponse = await validateChecklist(formData).unwrap()

      if (validationResponse.status === 'success') {
        const importFormData = new FormData()
        importFormData.append('file', file)
        importFormData.append('skip_review_and_publish', shouldSkipReview.toString())

        const importResponse = await importChecklist(importFormData).unwrap()

        if (importResponse.status === 'success') onImportSuccess?.()
      }
    } catch (error: any) {
      const validationError = error.data

      setValidationError(validationError)

      if (validationError?.type === 'Data Validations') {
        setShowDataValidationDialog(true)
        setDialogView(DialogViews.VALIDATION)
      } else {
        setDialogView(DialogViews.IMPORT)
      }
    }
  }

  const handleDataValidationClose = () => {
    setShowDataValidationDialog(false)

    handleDialogClose()
  }

  const onDrop = (files: File[]) => {
    setFile(files[0])

    setValidationError(null)
  }

  // render

  return (
    <>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', p: 2.5 }}>
        <Box>
          <Typography sx={{ textAlign: 'center' }} variant="body1">
            No fields are configured.
          </Typography>

          {hasCreateAccess && (
            <Typography sx={{ textAlign: 'center', width: 250 }} variant="body1">
              Start by adding a field or import directly from a .xlsx file.
            </Typography>
          )}
        </Box>

        {hasCreateAccess && (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button onClick={handleDialogOpen} startIcon={<FileUploadIcon />} variant="contained">
              Import .xlsx
            </Button>

            <Button onClick={handleAddField} variant="contained">
              Add field
            </Button>
          </Box>
        )}
      </Box>

      <Dialog
        PaperProps={{ sx: { minHeight: 284, width: '100%' } }}
        disableEscapeKeyDown={isLoading}
        onClose={isLoading ? undefined : showDataValidationDialog ? handleDataValidationClose : handleDialogClose}
        open={opening.isOpen || showDataValidationDialog || isLoading}
        sx={{ zIndex: Z_INDEX_MODAL }}
      >
        {dialogView === DialogViews.LOADING && <LoadingDialogContent isValidating={isValidating} />}

        {dialogView === DialogViews.VALIDATION && validationError && (
          <ValidationDialogContent onCancel={handleDataValidationClose} onConfirm={handleImportWithUnsupportedFields} validationError={validationError} />
        )}

        {dialogView === DialogViews.IMPORT && (
          <ImportFieldsDialogContent
            file={file}
            isLoading={isLoading}
            onClose={handleDialogClose}
            onDrop={onDrop}
            onSkipReviewChange={setShouldSkipReview}
            onSubmit={handleSubmit}
            shouldSkipReview={shouldSkipReview}
            validationError={validationError}
          />
        )}
      </Dialog>
    </>
  )
}

const ImportFieldsDialogContent: FC<_FieldImportDialogContentProps> = ({
  file,
  isLoading,
  onClose,
  onDrop,
  onSkipReviewChange,
  onSubmit,
  shouldSkipReview,
  validationError
}) => {
  return (
    <>
      <DialogTitle>Import fields from .xlsx file</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Dropzone
          accept=".xlsx"
          idleContent={
            <Typography variant="body2">
              {file ? (
                file.name
              ) : (
                <>
                  Drag and drop .xlsx file here, or{' '}
                  <Box component="span" sx={{ textDecoration: 'underline' }}>
                    browse files
                  </Box>
                </>
              )}
            </Typography>
          }
          maxFiles={1}
          multiple={false}
          onDrop={onDrop}
        />

        <FormControlLabel
          control={<Checkbox checked={shouldSkipReview} onChange={event => onSkipReviewChange(event.target.checked)} size="small" />}
          label={
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography variant="body2">Skip reviewing extraction samples and publish all configured fields.</Typography>

              <Tooltip
                arrow
                componentsProps={{ popper: { sx: { zIndex: Z_INDEX_MODAL } } }}
                placement="top"
                title="For better accuracy, we recommend reviewing the extraction samples before publishing. However, you can select this option to publish all configured fields now and review them later if needed."
              >
                <InfoOutlinedIcon sx={{ ml: 0.5, fontSize: 16, color: grey[700] }} />
              </Tooltip>
            </Box>
          }
          sx={{ maxWidth: 'fit-content' }}
        />

        {/* "Data Validations" are handled in <ValidationDialogContent> – all other validation errors are shown in the alert below. */}
        {validationError && validationError.type !== 'Data Validations' && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <AlertTitle>Error</AlertTitle>

            {validationError.message}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <Button color="primary" disabled={!file || isLoading} onClick={onSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </>
  )
}

const LoadingDialogContent: FC<_LoadingDialogContentProps> = ({ isValidating }) => (
  <DialogContent sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'center', p: 6 }}>
    <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
      <CircularProgress size={16} />

      <Typography variant="body2">{isValidating ? 'Validating checklist data…' : 'Importing checklist data…'}</Typography>
    </Box>

    <Typography variant="body2">This may take a few moments. Please do not refresh the page.</Typography>
  </DialogContent>
)

const ValidationDialogContent: FC<_ValidationDialogContentProps> = ({ onCancel, onConfirm, validationError }) => (
  <>
    <DialogTitle>Import with unsupported fields?</DialogTitle>

    <DialogContent>
      <Typography variant="body2">
        Some fields cannot be imported. Would you like to proceed and manually create these fields later in Klarity? The following fields will need to be
        created manually:
      </Typography>

      <Table size="small" sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Row</TableCell>
            <TableCell>Group</TableCell>
            <TableCell>Field</TableCell>
            <TableCell>Error Type</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {[...(validationError.errors ?? [])]
            .sort((a, b) => a.row_number - b.row_number)
            .map((error: CciChecklistValidationError, index) => (
              <TableRow key={index}>
                <TableCell>{error.row_number}</TableCell>
                <TableCell>{error.group_name}</TableCell>
                <TableCell>{error.field_name}</TableCell>
                <TableCell>{error.error_type}</TableCell>
                <TableCell>{error.error_info}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </DialogContent>

    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>

      <Button color="primary" onClick={onConfirm} variant="contained">
        Yes, import
      </Button>
    </DialogActions>
  </>
)
