import * as pdfjs from '../../../../pdfjs-dist-local'
import { PdfViewer } from '../PdfViewer'
import { _PdfDocumentProps } from '../DocumentPanel/DocumentPanel'
import { getPdfDocument } from '../../utils/documentApiUtils'
import DocumentPlaceholder from '../DocumentPlaceholder'
import PdfjsWorker from '../../../../pdfjs-dist-local/build/pdf.worker.entry'
import React, { useEffect, useRef, useState } from 'react'
import useSWR from 'swr'

// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorker

// types

type BasePDFDocProps = {
  data: any
  error: any
  loading?: boolean
}

// component

export const BasePDFDoc = ({ data, error, loading }: BasePDFDocProps) => {
  const [pdfDocument, setPdfDocument] = useState<any>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (data)
      // Use cMapUrl and cMapPacked to handle East Asian characters.
      pdfjs
        .getDocument({ cMapPacked: true, cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`, url: data })
        .promise.then(setPdfDocument)
  }, [data])

  return (
    <div ref={wrapperRef} style={{ height: '100%', overflow: 'scroll', maxWidth: '100%' }}>
      {!data && (
        <div>
          <DocumentPlaceholder error={loading ? false : !!error} />
        </div>
      )}

      {pdfDocument && <PdfViewer pdfDocument={pdfDocument} pdfScaleValue="auto" />}
    </div>
  )
}

export const PDFDoc = ({ documentFormat, documentId, loading }: _PdfDocumentProps) => {
  const { data, error } = useSWR(documentId + documentFormat, async () => {
    if (documentId && documentId !== 'upload') return getPdfDocument(documentId, documentFormat)
  })

  return <BasePDFDoc data={data} error={error} loading={loading} />
}
