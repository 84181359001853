import { ActiveComponents, useCciMainContext } from '../../CCI_Main'
import { Button } from '@mui/material'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import css from './style.module.scss'

export default function Footer() {
  const { leftPanelLoading, selectedItem, setActiveComponent, tab } = useCciMainContext()
  const [footerButton, setFooterButton] = useState<ReactNode>()

  const createFooterButton = useCallback(
    (activeComponent: ActiveComponents) =>
      setFooterButton(
        <div className={css.footer} style={{ padding: '0 20px' }}>
          <Button onClick={() => setActiveComponent(activeComponent)} variant="contained">
            {activeComponent}
          </Button>
        </div>
      ),
    [setActiveComponent]
  )

  const generateFooterButton = useCallback(
    (tab: string) => {
      switch (tab) {
        case 'checklist':
          createFooterButton(ActiveComponents.CREATE_FIELD)
          break
        case 'roles':
          setFooterButton(null)
          break
        case 'integrations':
          createFooterButton(ActiveComponents.CREATE_INTEGRATION)
          break
        case 'users':
          createFooterButton(ActiveComponents.CREATE_USER)
      }
    },
    [createFooterButton]
  )

  useEffect(() => generateFooterButton(tab), [generateFooterButton, tab])

  return <>{leftPanelLoading ? null : tab === 'integrations' ? (selectedItem ? null : footerButton) : footerButton}</>
}
