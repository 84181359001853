import { Box, Typography } from '@mui/material'
import { OnOffLabels } from '../../../../../utils/cci'
import React, { FC } from 'react'

// types

type _AccountingImpactCellProps = { displayAccountingImpact: boolean }

// components

export const AccountingImpactCell: FC<_AccountingImpactCellProps> = ({ displayAccountingImpact }) => (
  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
    <Typography variant="body2">{displayAccountingImpact ? OnOffLabels.ON : OnOffLabels.OFF}</Typography>
  </Box>
)
