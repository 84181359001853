import { Box } from '@mui/material'
import { Resizable as ReactResizable, ResizeCallbackData } from 'react-resizable'
import { clamp } from 'lodash'
import React, { Dispatch, FC, ReactNode, SetStateAction, SyntheticEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import css from './style.module.scss'

// types

type _ResizableHandleProps = { cursor: Cursors; handleAxis?: any }

type _ResizableProps = {
  children: ReactNode
  height: number
  isHandleHidden?: boolean
  onResize?: () => void
  setHeight: Dispatch<SetStateAction<number>>
  sx?: any
}

// constants

const COLOR_RESIZEABLE_HANDLE = '#888'
export const RESIZABLE_MINIMUM_HEIGHT = 218

// enums

export enum Cursors {
  BETWEEN = 'ns-resize',
  MAXIMUM = 's-resize',
  MINIMUM = 'n-resize'
}

// components

export const Resizable: FC<_ResizableProps> = ({ children, height, isHandleHidden = false, onResize, setHeight, sx }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isResizing, setIsResizing] = useState(false)
  const [maximumHeight, setMaximumHeight] = useState(RESIZABLE_MINIMUM_HEIGHT)

  const handleResize = useCallback(
    (_: SyntheticEvent, { size }: ResizeCallbackData) => {
      setHeight(clamp(size.height, RESIZABLE_MINIMUM_HEIGHT, maximumHeight))
      onResize?.()
    },
    [maximumHeight, onResize, setHeight]
  )

  useEffect(() => {
    if (ref.current) {
      setMaximumHeight((ref.current.parentNode as HTMLDivElement).offsetHeight * 0.9)
    }
  }, [])

  const cursor = height === maximumHeight ? Cursors.MAXIMUM : height === RESIZABLE_MINIMUM_HEIGHT ? Cursors.MINIMUM : Cursors.BETWEEN

  return (
    <Box ref={ref} sx={{ pointerEvents: isResizing ? 'none' : 'auto', ...sx }}>
      <ReactResizable
        className={css.resizable}
        handle={isHandleHidden ? null : <ResizableHandle cursor={cursor} />}
        height={height}
        onResize={handleResize}
        onResizeStart={() => setIsResizing(true)}
        onResizeStop={() => setIsResizing(false)}
        resizeHandles={isHandleHidden ? [] : ['n']}
        width={0}
      >
        {children}
      </ReactResizable>
    </Box>
  )
}

// See: https://github.com/react-grid-layout/react-resizable/issues/175#issuecomment-1101142158
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResizableHandle = forwardRef<HTMLDivElement, _ResizableHandleProps>(({ cursor, handleAxis, ...rest }, ref) => (
  <Box
    ref={ref}
    sx={{
      '&:hover': { backgroundColor: COLOR_RESIZEABLE_HANDLE },
      backgroundColor: COLOR_RESIZEABLE_HANDLE,
      cursor,
      height: 4,
      position: 'absolute',
      width: '100%',
      zIndex: 2
    }}
    {...rest}
  />
))
