import { ActiveComponents, useCciMainContext } from '../../../CCI_Main'
import { Box, Checkbox, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material'
import { CHECKBOX_SX } from '../Checklist'
import { Features, Permissions, useUserAccess } from '../../../../../hooks/useUserAccess'
import { ReactComponent as Status } from '../../../../../assets/status.svg'
import { Statuses } from '../../../../../utils/cci'
import { common, grey, lightGreen, purple, red, yellow } from '@mui/material/colors'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'

// types

type _FieldNameCellProps = { description?: string; id: string; isDeleted?: boolean; name: string; status: Statuses | string }

// components

export const FieldNameCell: FC<_FieldNameCellProps> = ({ description, id, isDeleted, name, status }) => {
  const { checkedFields, documentTypesField, fieldOverlay, setActiveComponent, setCheckedFields } = useCciMainContext()
  const hasDeleteFieldPermission = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.DELETE_FIELD })
  const hasReorderPermission = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.REORDER })

  const activeComponent = useMemo(
    () => (id === documentTypesField?.id ? ActiveComponents.EDIT_DOCUMENT_TYPES : ActiveComponents.EDIT_FIELD),
    [documentTypesField, id]
  )

  const { fieldId, setFieldId } = useMemo(() => fieldOverlay, [fieldOverlay])
  const isPaneOpen = useMemo(() => id === fieldId && !fieldOverlay.isClosing, [fieldId, fieldOverlay.isClosing, id])

  const color = useMemo(() => {
    switch (status) {
      case Statuses.DRAFT:
        return purple[300]
      case Statuses.IN_REVIEW:
        return yellow[500]
      case Statuses.PUBLISHED:
        return lightGreen[700]
      default:
        return red[300]
    }
  }, [status])

  const iconButtonSx = useMemo(
    () => ({
      mr: -0.5,
      my: -1,
      ...(isPaneOpen && { bgcolor: 'primary.main', color: common.white }),
      ':hover': { ...(isPaneOpen ? { bgcolor: 'primary.main' } : { color: common.black }) },
      '& .MuiSvgIcon-root': { transform: 'rotate(-90deg)' }
    }),
    [isPaneOpen]
  )

  const handleCheckboxChange = useCallback(
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
      setCheckedFields(current => (checked ? [...current, id] : current.filter(fieldId => fieldId !== id))),
    [id, setCheckedFields]
  )

  const handleClick = useCallback(() => {
    if (!fieldOverlay.isOpen) {
      fieldOverlay.open(id)

      setActiveComponent(activeComponent)
    } else if (id === fieldId) {
      fieldOverlay.close()

      setActiveComponent(null)
    } else {
      setActiveComponent(activeComponent)

      setFieldId(id)
    }
  }, [activeComponent, fieldId, fieldOverlay, id, setActiveComponent, setFieldId])

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
        {(hasDeleteFieldPermission || hasReorderPermission) && (
          <Checkbox
            checked={checkedFields.includes(id)}
            disabled={isDeleted}
            onChange={handleCheckboxChange}
            size="small"
            sx={{ ...CHECKBOX_SX, ...(isDeleted && { visibility: 'hidden' }) }}
          />
        )}

        <SvgIcon
          component={Status}
          sx={{ bottom: 2, color, height: 18, position: 'relative', left: hasDeleteFieldPermission || hasReorderPermission ? 1 : 3 }}
        />

        <Typography sx={{ fontSize: 14, maxWidth: 270, ml: 1.25, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{name}</Typography>

        {description && (
          <Tooltip arrow placement="top" title={description}>
            <InfoOutlinedIcon sx={{ color: grey[600], fontSize: 16 }} />
          </Tooltip>
        )}
      </Box>

      {isDeleted ? (
        <Tooltip arrow placement="top" title="Pending removal…">
          <DeleteOutlineIcon color="disabled" sx={{ mr: 0.25 }} />
        </Tooltip>
      ) : (
        <IconButton onClick={handleClick} size="small" sx={iconButtonSx}>
          <ExpandMoreIcon />
        </IconButton>
      )}
    </Box>
  )
}
