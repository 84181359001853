import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { CurrentRuntimeEnvironment } from '../utils/envUtils'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { _TableCell } from '../components/DocumentPanel/TablesQueryButton/TablesQueryButton'
import { captureError } from '../utils/sentry'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '../utils/sessionApiUtils'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

// types

type AiAnalyticsChatQueryParams = {
  file_id?: string | null
  query?: string
  thread_id?: string | null
}

export type AiAnalyticsChatQueryError = {
  error: string
  message: string
}

export type AiAnalyticsChatQueryResponse = {
  file_id?: string
  response?: {
    image?: string // base64 encoded image
    text?: string
  }
  thread_id?: string
}

export type CciChecklistValidationError = {
  checklist_type: string
  error_info: string
  error_type: string
  field_name: string
  group_name: string
  row_number: number
}

export type CciChecklistValidationResponse = {
  errors?: CciChecklistValidationError[] | null
  message?: string | null
  status: 'success' | 'error'
  type?: 'Columns Validations' | 'Data Validations' | null
}

export type CciChecklistImportResponse = {
  message?: string | null
  status: 'success' | 'error'
  type?: string | null
}

export type AiLanguageAnalysisParams = { checklist_fields: string[]; document_types: string[]; query: string; semantic_search_query: string }
export type AiLanguageAnalysisResponse = { response: { error?: string; text?: string } }

export type AxiosParams = {
  data?: AxiosRequestConfig['data']
  method: AxiosRequestConfig['method']
  params?: AxiosRequestConfig['params']
  url: string
}

type ChatQueryParams = {
  documentId: string
  query: string
}

type ChatQueryResponse = string

type ChecklistGptQueryParams = { action_type: string; field_name: string }

type ChecklistGptQueryResponse = { [key: string]: any }[]

type DealPageChatStatusParams = {
  dealId: string
}

type DealPageChatStatusResponse = {
  [documentId: string]: boolean
}

type DocumentPageChatStatusParams = {
  documentId: string
}

type DocumentPageChatStatusResponse = boolean

type SemanticSearchParams = { checklist_fields?: string[]; document_types?: string[]; query: string }

export type SemanticSearchResponse = {
  match_score: number
  match_text: string
  meta_data: {
    counterparty: string
    created_at: string
    document_id: string
    document_name: string
    document_type: string
    low_confidence: boolean
  }
}[]

export enum TableStatuses {
  TABLES_NOT_PROCESSED = 'TABLES_NOT_PROCESSED',
  TABLES_PROCESSED = 'TABLES_PROCESSED'
}

type TableStatusParams = {
  documentId: string
}

type TableStatusResponse = {
  status: TableStatuses
}

type TablesResponse = { page_number: number; tables: _TableCell[][][] }[]

type TablesParams = {
  documentId: string
}

// constants

const ERROR_MESSAGES = {
  default: 'Something went wrong performing your request.',
  table: {
    default: 'Error loading table data. Click the table icon in the toolbar to try again.',
    largeDocument: 'On-demand table extraction for large documents is not supported.' // This needs to mirror the exact error message returned by the server.
  }
}

export const REST_API_TAGS = {
  tables: 'Tables',
  tableStatus: 'Table Status'
}

// init

const client = axios.create()

// functions

const axiosBaseQuery =
  ({ baseUrl, prepareHeaders }: FetchBaseQueryArgs): BaseQueryFn<AxiosParams, unknown, unknown> =>
  async ({ data, method, params, url }, api) => {
    try {
      const headers: Record<string, string> = {}
      const preparedHeaders = prepareHeaders == null ? new Headers({}) : await prepareHeaders(new Headers({}), api)

      for (const [key, value] of Array.from(preparedHeaders.entries())) {
        headers[key] = value
      }

      const fullUrl = url.startsWith('http') ? url : `${baseUrl}${url}`
      const result = await client({ data, headers, method, params, url: fullUrl })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      captureError(err)

      return { error: { data: err.response?.data || err.message, status: err.response?.status } }
    }
  }

export const errorReportingMiddleware =
  // @ts-ignore
  (api, setErrorMessage: Dispatch<SetStateAction<string>>, setExtendedErrorMessage: Dispatch<SetStateAction<string>>) => next => action => {
    if (isRejectedWithValue(action)) {
      const queryCacheKey = action?.meta?.arg?.queryCacheKey
      const endpointName = action?.meta?.arg?.endpointName

      if (queryCacheKey?.includes('getChatStatus') || endpointName === 'validateCciChecklist') return next(action)

      const networkErrorMessage = action?.payload?.data?.message

      let errorMessage = ERROR_MESSAGES.default

      if (queryCacheKey?.includes('getTable')) {
        if (networkErrorMessage === ERROR_MESSAGES.table.largeDocument) return next(action)

        errorMessage = ERROR_MESSAGES.table.default
      }

      setErrorMessage(errorMessage)

      setExtendedErrorMessage(JSON.stringify({ action }, null, 2))
    }

    return next(action)
  }

// hooks

export const useRestApiUtils = () => {
  const dispatch = useDispatch()

  const { invalidateTags, resetApiState } = restApi.util

  return useMemo(
    () => ({
      invalidateTags: (tags: string[]) => dispatch(invalidateTags(tags)),
      resetApiState: () => dispatch(resetApiState())
    }),
    [dispatch, invalidateTags, resetApiState]
  )
}

// api

export const restApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: CurrentRuntimeEnvironment.REACT_APP_API_ROOT,

    prepareHeaders: async headers => {
      const idToken = await getIdToken()

      if (idToken) {
        headers.set('Authorization', `Bearer ${btoa(`${idToken}__||__${localStorage.getItem('customerId')}`)}`)
      }

      return headers
    }
  }),

  endpoints: build => ({
    validateCciChecklist: build.mutation<CciChecklistValidationResponse, FormData>({
      query: formData => ({ method: 'post', url: '/cci/checklist/validate', data: formData })
    }),
    importCciChecklist: build.mutation<CciChecklistImportResponse, FormData>({
      query: formData => ({ method: 'post', url: '/cci/checklist/import', data: formData })
    }),
    getChecklistGptOptions: build.query<ChecklistGptQueryResponse, ChecklistGptQueryParams>({
      query: ({ action_type, field_name }) => ({ method: 'post', url: '/checklist_gpt', data: { action_type, field_name } })
    }),
    getDealPageChatStatus: build.query<DealPageChatStatusResponse, DealPageChatStatusParams>({
      query: ({ dealId }) => ({ method: 'get', url: `/deals/${dealId}/chat/status` })
    }),
    getDocumentPageChatStatus: build.query<DocumentPageChatStatusResponse, DocumentPageChatStatusParams>({
      query: ({ documentId }) => ({ method: 'get', url: `/documents/${documentId}/chat/status` })
    }),
    getTables: build.query<TablesResponse, TablesParams>({
      providesTags: (_, __, { documentId }) => [{ type: REST_API_TAGS.tables, id: documentId }],
      query: ({ documentId }) => ({ method: 'get', url: `/documents/${documentId}/tables` })
    }),
    getTableStatus: build.query<TableStatusResponse, TableStatusParams>({
      providesTags: (_, __, { documentId }) => [{ type: REST_API_TAGS.tableStatus, id: documentId }],
      query: ({ documentId }) => ({ method: 'get', url: `/documents/${documentId}/table-status` })
    }),
    processTables: build.mutation({
      invalidatesTags: (_, __, { documentId }) => [{ type: REST_API_TAGS.tableStatus, id: documentId }],
      query: ({ documentId }) => ({ method: 'get', url: `/documents/${documentId}/tables` })
    }),
    submitAiAnalyticsChatQuery: build.mutation<AiAnalyticsChatQueryResponse, AiAnalyticsChatQueryParams>({
      query: ({ file_id, query, thread_id }) => ({ method: 'post', url: '/ai_analysis_chat', data: { file_id, query, thread_id } })
    }),
    submitAiLanguageAnalysis: build.query<AiLanguageAnalysisResponse, AiLanguageAnalysisParams>({
      query: data => ({ method: 'post', url: '/ai_language_analysis', data })
    }),
    submitChatQuery: build.mutation<ChatQueryResponse, ChatQueryParams>({
      query: ({ documentId, query }) => ({ method: 'post', url: `/documents/${documentId}/chat`, data: { query } })
    }),
    submitSemanticSearch: build.query<SemanticSearchResponse, SemanticSearchParams>({
      query: data => ({ method: 'post', url: '/semantic_search', data })
    })
  }),

  reducerPath: 'restApi',

  tagTypes: Object.values(REST_API_TAGS)
})

export const {
  useGetChecklistGptOptionsQuery,
  useGetDealPageChatStatusQuery,
  useGetDocumentPageChatStatusQuery,
  useGetTableStatusQuery,
  useGetTablesQuery,
  useImportCciChecklistMutation,
  useLazyGetTableStatusQuery,
  useLazyGetTablesQuery,
  useLazySubmitAiLanguageAnalysisQuery,
  useLazySubmitSemanticSearchQuery,
  useProcessTablesMutation,
  useSubmitAiAnalyticsChatQueryMutation,
  useSubmitChatQueryMutation,
  useValidateCciChecklistMutation
} = restApi
