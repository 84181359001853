import useCurrentUser from './useCurrentUser'

// types

export enum Features {
  ANALYTICS = 'Analytics',
  ANALYTICS_CHAT = 'Analytics Chat',
  ANNOTATOR = 'Annotator',
  ATTACHMENT = 'Attachment',
  ATTACHMENTS_DASHBOARD = 'AttachmentsDashboard',
  AUTOMATED_DOCUMENT = 'AutomatedDocument',
  CCI = 'CCI',
  CCI_CHECKLIST_TAB = 'CCI_Checklist_Tab',
  CHAT = 'Chat',
  CHECKLIST_GPT = 'ChecklistGPT',
  COLLISION = 'Collision',
  COULD_NOT_EVALUATE_MATCHING_STATE = 'Could Not Evaluate Matching State',
  CUSTOMER_DATA = 'CustomerData',
  CUSTOMER_DATA_WITH_PDFS = 'CustomerData with PDFs',
  DATA_COMPLETENESS = 'DataCompleteness',
  DATA_TABLE_EDITOR_V3 = 'DataTableEditorV3',
  DEAL = 'Deal',
  DEAL_DASHBOARD = 'DealDashboard',
  DEMO_AUDITOR_BUTTON = 'DEMO__AuditorButton',
  DOCUMENT = 'Document',
  DOCUMENT_DASHBOARD = 'DocumentDashboard',
  MANUAL_DOCUMENT = 'ManualDocument',
  NOVELTY = 'Novelty',
  SEMANTIC_SEARCH = 'SemanticSearch',
  SEMANTIC_SEARCH_V2 = 'SemanticSearchV2',
  SUPER_ANNOTATOR = 'SuperAnnotator',
  TEXT_SEARCH = 'TextSearch'
}

export enum Permissions {
  ACCOUNT_LEVEL = 'ACCOUNT_LEVEL',
  ADMIN = 'ADMIN',
  BULK_V1 = 'BULK',
  BULK_V2 = 'BULK_V2',
  CHECKLIST_TAB = 'CHECKLIST_TAB',
  CREATE = 'CREATE',
  CREATE_FIELD = 'CREATE_FIELD',
  CREATE_FIELD_LIMITED = 'CREATE_FIELD_LIMITED',
  DELETE = 'DELETE',
  DELETE_FIELD = 'DELETE_FIELD',
  DOWNLOAD = 'DOWNLOAD',
  EDIT_DOCUMENT_TYPES = 'EDIT_DOCUMENT_TYPES',
  EDIT_EXTRACTION_CONFIGURATION = 'EDIT_EXTRACTION_CONFIGURATION',
  EDIT_FIELD = 'EDIT_FIELD',
  EDIT_FIELD_LIMITED = 'EDIT_FIELD_LIMITED',
  EDIT_NEUTRAL_TAG = 'EDIT_NEUTRAL_TAG',
  ENABLED = 'ENABLED',
  EXPORT = 'EXPORT',
  INTEGRATIONS_TAB = 'INTEGRATIONS_TAB',
  READ = 'READ',
  REORDER = 'REORDER',
  ROLES_TAB = 'ROLES_TAB',
  UPDATE = 'UPDATE',
  UPDATE_COUNTERPARTY = 'UPDATE_COUNTERPARTY',
  USERS_TAB = 'USERS_TAB',
  WRITE = 'WRITE'
}

type _UserAccessParams = { feature: Features; permission: Permissions }

// hooks

export const useHasChecklistGptAccess = () => useUserAccess({ feature: Features.CHECKLIST_GPT, permission: Permissions.READ })

export const useHasDataTableEditorV3Access = () => useUserAccess({ feature: Features.DATA_TABLE_EDITOR_V3, permission: Permissions.ENABLED })

export const useIsAnnotator = () => useUserAccess({ feature: Features.ANNOTATOR, permission: Permissions.READ })

export const useIsSuperAnnotator = () => useUserAccess({ feature: Features.SUPER_ANNOTATOR, permission: Permissions.READ })

export const useUserAccess = ({ feature, permission }: _UserAccessParams) => {
  const currentUser = useCurrentUser()

  for (const r of currentUser?.roles?.edges || []) {
    for (const f of r?.node?.features?.edges || []) {
      if (!f?.node) continue
      if (f.node.name === feature && f.node.permission === permission) {
        return true
      }
    }
  }

  return false
}
