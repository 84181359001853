import { EXCLUDED_PASTE_TYPES } from '../constants'
import GC from '@mescius/spread-sheets'

// functions

export const createFreezeActions = (workbook: GC.Spread.Sheets.Workbook, updateContextMenu: () => void) => {
  const sheet = workbook.getActiveSheet()

  const hasFrozenColumns = sheet.frozenColumnCount() > 0
  const hasFrozenRows = sheet.frozenRowCount() > 0

  const selections = sheet.getSelections()

  const selectedColumn = selections?.[0]?.col ?? 0
  const selectedColumnLetter = getColumnLetter(selectedColumn)
  const selectedRowNumber = (selections?.[0]?.row ?? 0) + 1

  const freezeColumn = () => {
    sheet.frozenColumnCount(selectedColumn + 1)
    updateContextMenu()
  }

  const freezeRow = () => {
    sheet.frozenRowCount(selectedRowNumber)
    updateContextMenu()
  }

  const unfreezeColumns = () => {
    sheet.frozenColumnCount(0)
    updateContextMenu()
  }

  const unfreezeRows = () => {
    sheet.frozenRowCount(0)
    updateContextMenu()
  }

  const freezeColumnAction = { command: freezeColumn, name: 'freezeColumn', text: `Freeze up to column ${selectedColumnLetter}`, workArea: 'colHeader' }

  const freezeRowAction = { command: freezeRow, name: 'freezeRow', text: `Freeze up to row ${selectedRowNumber}`, workArea: 'rowHeader' }

  const unfreezeColumnsAction = { command: unfreezeColumns, name: 'unfreezeColumns', text: 'Unfreeze columns', workArea: 'colHeader' }

  const unfreezeRowsAction = { command: unfreezeRows, name: 'unfreezeRows', text: 'Unfreeze rows', workArea: 'rowHeader' }

  return [freezeColumnAction, ...(hasFrozenColumns ? [unfreezeColumnsAction] : []), freezeRowAction, ...(hasFrozenRows ? [unfreezeRowsAction] : [])]
}

const getColumnLetter = (index: number) => {
  let letter = ''

  while (index >= 0) {
    letter = String.fromCharCode((index % 26) + 65) + letter

    index = Math.floor(index / 26) - 1
  }

  return letter
}

export const setContextMenu = (workbook: GC.Spread.Sheets.Workbook) => {
  const defaultMenuItems = workbook.contextMenu.menuData.filter(item => {
    if (!item.name) return false

    if (EXCLUDED_PASTE_TYPES.some(type => item.name?.toLowerCase().includes(type))) return false

    const isAllowedAction = ['copy', 'cut', 'paste'].some(action => item.name?.startsWith(`gc.spread.${action}`))

    const isAllowedRowColumnAction =
      (item.name.includes('Row') || item.name.includes('Column')) && ['delete', 'insert'].some(action => item.name?.includes(action))

    return isAllowedAction || isAllowedRowColumnAction
  })

  const getContextMenuItems = () => {
    const filterMenuItem = (item: any, allowedActions: string[]) => {
      const isHeaderRowSelected = workbook
        .getActiveSheet()
        ?.getSelections()
        ?.some(selection => selection.row === 0 || (selection.rowCount > 1 && selection.row === 0))

      // Prevent row deletion/insertion operations on the header row.
      if (isHeaderRowSelected && (item.name?.includes('delete') || item.name?.includes('insert'))) return false

      if (EXCLUDED_PASTE_TYPES.some(type => item.name?.toLowerCase().includes(type))) return false

      return allowedActions.some(action => item.name?.includes(action))
    }

    return [
      ...defaultMenuItems.filter(item => filterMenuItem(item, ['copy', 'cut', 'paste'])),
      { type: 'separator' },
      ...defaultMenuItems.filter(item => filterMenuItem(item, ['delete', 'insert'])),
      { type: 'separator' },
      ...createFreezeActions(workbook, updateContextMenu)
    ]
  }

  const updateContextMenu = () => {
    workbook.contextMenu.menuData = getContextMenuItems()

    workbook.getActiveSheet().repaint()
  }

  updateContextMenu()

  workbook.bind(GC.Spread.Sheets.Events.SelectionChanged, updateContextMenu)
}
