import { Box, CircularProgress, InputAdornment, SxProps, TextField } from '@mui/material'
import { useAppContext } from '../app'
import React, { FC, useCallback } from 'react'
import SearchIcon from '@mui/icons-material/Search'

// types

type _SearchProps = { onChange?: () => void; sx?: SxProps }

// components

export const Search: FC<_SearchProps> = ({ onChange, sx = {} }) => {
  const { isSearchDataLoading, searchPlaceholder, searchTerm, setSearchTerm } = useAppContext()

  const handleChange = useCallback(
    ({ currentTarget: { value } }) => {
      setSearchTerm(value)

      onChange?.()
    },
    [onChange, setSearchTerm]
  )

  return (
    <Box sx={{ p: 2, ...sx }}>
      <TextField
        InputProps={{
          autoComplete: 'off',
          startAdornment: (
            <InputAdornment position="start">
              {isSearchDataLoading ? <CircularProgress color="inherit" size={14} sx={{ ml: 0.375, mr: 0.25 }} /> : <SearchIcon sx={{ width: 20 }} />}
            </InputAdornment>
          ),
          sx: { fontSize: 14 }
        }}
        disabled={isSearchDataLoading}
        fullWidth
        inputProps={{ 'aria-label': searchPlaceholder }}
        onChange={handleChange}
        placeholder={searchPlaceholder}
        size="small"
        sx={{ '& .MuiInputAdornment-root': { ml: 0, mr: -0.375 }, '& .MuiInputBase-root': { pl: 1 } }}
        type="search"
        value={searchTerm}
      />
    </Box>
  )
}
