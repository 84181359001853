import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { CurrentRuntimeEnvironment } from '../../utils/envUtils'
import { SessionStorageWrapper, persistCache } from 'apollo3-cache-persist'
import { Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { cache } from '../../utils/apolloClient'
import { useOpening } from '@hoologic/use-opening'
import React, { FC, useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'

// constants

const APOLLO_CACHE_PERSIST_KEY = 'apollo-cache-persist'

const APOLLO_CACHE_PERSIST_IS_ENABLED_KEY = 'apollo-cache-persist-is-enabled'

const IS_DEV_MENU_ENABLED = process.env.NODE_ENV !== 'production' && CurrentRuntimeEnvironment.REACT_APP_IS_DEV_MENU_ENABLED === 'true'

// services

/**
 * Controls Apollo cache persistence on an as-needed basis to reduce load times. Must be explicitly enabled in each tab since it uses session storage.
 */
const apolloCachePersist = {
  disable() {
    sessionStorage.setItem(APOLLO_CACHE_PERSIST_IS_ENABLED_KEY, 'false')

    this.removeSessionStorageValue()
  },

  async enable() {
    sessionStorage.setItem(APOLLO_CACHE_PERSIST_IS_ENABLED_KEY, 'true')

    await this.persistCache()
  },

  initialize() {
    if (sessionStorage.getItem(APOLLO_CACHE_PERSIST_IS_ENABLED_KEY) === null) sessionStorage.setItem(APOLLO_CACHE_PERSIST_IS_ENABLED_KEY, 'false')

    if (this.isEnabled()) this.persistCache()
  },

  isEnabled() {
    return sessionStorage.getItem(APOLLO_CACHE_PERSIST_IS_ENABLED_KEY) === 'true'
  },

  async persistCache() {
    try {
      await persistCache({ cache, storage: new SessionStorageWrapper(window.sessionStorage) })
    } catch (error) {
      console.error('Error setting up cache persistence:', error)
    }
  },

  async removeSessionStorageValue() {
    sessionStorage.removeItem(APOLLO_CACHE_PERSIST_KEY)
  }
}

if (IS_DEV_MENU_ENABLED) apolloCachePersist.initialize()

// components

export const DevMenu: FC = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const opening = useOpening()

  const closeMenu = () => {
    opening.close()

    setIsButtonVisible(false)
  }

  const disableApolloCachePersist = () => {
    apolloCachePersist.disable()

    closeMenu()

    window.location.reload()
  }

  const enableApolloCachePersist = async () => {
    await apolloCachePersist.enable()

    closeMenu()

    window.location.reload()
  }

  return IS_DEV_MENU_ENABLED ? (
    <Box onMouseEnter={() => setIsButtonVisible(true)} onMouseLeave={() => !opening.isOpen && setIsButtonVisible(false)}>
      {/* Hidden menu button appears on hover at the top-right corner of the screen. */}
      <IconButton
        onClick={opening.toggle}
        size="small"
        sx={{ fontSize: 12, opacity: isButtonVisible ? 1 : 0, position: 'fixed', right: 0, top: 0, transition: 'all 150ms linear', zIndex: Z_INDEX_OVERLAY }}
      >
        <SettingsIcon fontSize="inherit" />
      </IconButton>

      <Menu MenuListProps={{ dense: true }} anchorEl={opening.anchor} onClose={closeMenu} open={opening.isOpen} sx={{ zIndex: Z_INDEX_OVERLAY }}>
        <MenuItem onClick={apolloCachePersist.isEnabled() ? disableApolloCachePersist : enableApolloCachePersist}>
          {apolloCachePersist.isEnabled() ? 'Disable' : 'Enable'} apollo-cache-persist
        </MenuItem>
      </Menu>
    </Box>
  ) : null
}
