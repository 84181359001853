import { DELETE } from '../../../../../../../utils/cci'
import { DataPointFieldMentions } from '../../../../../../../components/DataPointFieldMentions'
import { FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { useFieldValuesContext } from '../FieldValuesProvider'
import Button from '../../../../../../../components/Button'
import React, { FC, useCallback, useState } from 'react'
import css from './style.module.scss'

// constants

const FIELD = 'extraction_description'

const LABEL = 'AI Prompt'

// components

export const ExtractionDescriptionInput: FC = () => {
  const { isEditView } = useChecklistConfigContext()
  const { selectedItem } = useCciMainContext()
  const { fieldValues, updateFieldValue } = useFieldValuesContext()

  const selectedExtractionMethod = JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}')
  const { description } = selectedExtractionMethod

  const [value, setValue] = useState(description || '')

  const handleChange = (event: { target: { value: string } }) => {
    const { value } = event.target

    setValue(value)

    const trimmedNewValue = value.trim()

    if (!trimmedNewValue) {
      updateFieldValue(FIELD, description && isEditView ? DELETE : null)
    } else if (trimmedNewValue === description) {
      updateFieldValue(FIELD, null)
    } else {
      updateFieldValue(FIELD, trimmedNewValue)
    }
  }

  const handleClear = useCallback(() => {
    setValue('')

    updateFieldValue(FIELD, description && isEditView ? DELETE : null)
  }, [description, isEditView, updateFieldValue])

  if (!fieldValues?.extraction_method_id && !selectedExtractionMethod.extraction_method_id) return null

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="extraction-description-input" sx={{ fontWeight: 600, minWidth: 164, mr: 2 }} variant="body2">
        {LABEL}
      </Typography>

      <DataPointFieldMentions id="extraction-description-input" label={LABEL} onChange={handleChange} value={value && value !== DELETE ? value : ''} />

      {value && value !== DELETE && <Button className={css.cciButton} icon={<FiXSquare />} onClick={handleClear} />}
    </div>
  )
}
