import { SPREADSHEET_FILE_MIME_TYPES, convertBase64ToBlob, createFileFromBlob, getSpreadsheetFileExtension } from '../utils/fileUtils'
import { captureError } from './sentry'
import axiosClient from '../utils/axiosClient'
import type { _PdfDocumentFormat } from '../components/DocumentPanel/DocumentPanel'

// constants

const DOCUMENTS_CACHE = 'documents-cache'

// functions

const addDocumentToCache = async (cacheKey: string, response: string): Promise<void> => {
  try {
    const cacheStorage = await caches.open(DOCUMENTS_CACHE)

    await cacheStorage.put(cacheKey, new Response(JSON.stringify(response), { headers: { 'Content-Type': 'application/json' }, status: 200, statusText: 'OK' }))
  } catch (error) {
    captureError(error, 'Error caching document')
  }
}

export const downloadDocument = async (documentId: string, documentName: string) => {
  const {
    data: { data }
  } = await axiosClient.get(`/document/${documentId}?type=ORIGINAL_PDF`)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = data
  // documentName is alias if present- passed into toolbar as alias || name
  a.download = `${documentName.split('.')[0]}.pdf` // TODO: return name w/ response. this is built on the assumption that all document names end with .docx

  document.body.appendChild(a).click()
  document.body.removeChild(a)
}

export const getPdfDocument = async (documentId: string, type: _PdfDocumentFormat = 'ORIGINAL_PDF') => {
  const cacheKey = `${documentId}-${type}`

  // Attempt to get from cache first.
  try {
    const cacheStorage = await caches.open(DOCUMENTS_CACHE)
    const cachedResponse = await cacheStorage.match(cacheKey)

    if (cachedResponse) return cachedResponse.json()
  } catch (error) {
    captureError(error, 'Error getting PDF document from cache')
  }

  // If not in cache or cache failed, get from API.
  const response = (await axiosClient.get(`/document/${documentId}?type=${type}`)).data.data

  await addDocumentToCache(cacheKey, response)

  return response
}

export const getSpreadsheetDocument = async (documentId: string, documentName: string): Promise<File | null> => {
  const fileExtension = getSpreadsheetFileExtension(documentName)

  if (!fileExtension) throw new Error('Unsupported file type.')

  const type = fileExtension.toUpperCase()
  const cacheKey = `${documentId}-${type}`

  // Attempt to get from cache first.
  try {
    const cacheStorage = await caches.open(DOCUMENTS_CACHE)
    const cachedResponse = await cacheStorage.match(cacheKey)

    if (cachedResponse) {
      const base64Data = await cachedResponse.json()
      const mimeType = SPREADSHEET_FILE_MIME_TYPES[fileExtension]
      const blob = convertBase64ToBlob(base64Data, mimeType)

      return createFileFromBlob(blob, documentName)
    }
  } catch (error) {
    captureError(error, 'Error getting spreadsheet from cache')
  }

  // If not in cache or cache failed, get from API.
  const url = `/document/${documentId}?type=${type}`
  const response = await axiosClient(url)
  const base64Data = response.data.data

  await addDocumentToCache(cacheKey, base64Data)

  const mimeType = SPREADSHEET_FILE_MIME_TYPES[fileExtension]
  const blob = convertBase64ToBlob(base64Data, mimeType)

  return createFileFromBlob(blob, documentName)
}

export const getTextDocument = async (documentId: string): Promise<string> => {
  const type = 'TXT'
  const cacheKey = `${documentId}-${type}`

  // Attempt to get from cache first.
  try {
    const cacheStorage = await caches.open(DOCUMENTS_CACHE)
    const cachedResponse = await cacheStorage.match(cacheKey)

    if (cachedResponse) {
      const data = await cachedResponse.json()
      const base64Content = data.includes('base64,') ? data.split('base64,')[1] : data

      return window.atob(base64Content)
    }
  } catch (error) {
    captureError(error, 'Error getting text document from cache')
  }

  // If not in cache or cache failed, get from API.
  const response = (await axiosClient.get(`/document/${documentId}?type=${type}`)).data.data

  await addDocumentToCache(cacheKey, response)

  const base64Content = response.includes('base64,') ? response.split('base64,')[1] : response

  return window.atob(base64Content)
}

export const saveDocument = async (documentId: string, version_name?: string | null) => {
  try {
    // @ts-ignore
    await window.editor.saveDocument()
  } catch (error) {
    captureError(error)
  }

  return await axiosClient.post(`/document/${documentId}`, { version_name })
}
