import { API_ROOT } from './apiUtils'
import { ApolloClient, ApolloLink, InMemoryCache, Operation, createHttpLink, from } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'
import { Kind } from 'graphql'
import { getIdToken } from './sessionApiUtils'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { t } from 'i18next'

// types

type MutationControlLink = ApolloLink & { activeQueries: Map<string, AbortController> }

// functions

const checkForMutation = (operation: Operation) =>
  operation.query.definitions.some(definition => definition.kind === Kind.OPERATION_DEFINITION && definition.operation === 'mutation')

// links

const authLink = setContext(async (_, { headers }) => {
  const idToken = await getIdToken()
  const customerId = localStorage.getItem('customerId')
  const token = btoa(`${idToken}__||__${customerId}`)

  // return the headers to the context so httpLink can read them
  return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } }
})

const errorLink = (
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setExtendedErrorMessage: Dispatch<SetStateAction<string>>,
  setPendingMutationCount: Dispatch<SetStateAction<number>>
) =>
  onError(errorResponse => {
    const { graphQLErrors, networkError, operation, response } = errorResponse

    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        setExtendedErrorMessage(JSON.stringify({ graphQLError, networkError, operation, response }, null, 2))

        const { locations, message, path } = graphQLError

        console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)

        if (message?.includes('not a valid ObjectId') || message?.includes('Invalid id')) return // Errors handled by rendering <ErrorPage />.

        if (message === 'Confirmation required' && path?.includes('move_to_next_internal_state')) return // Error handled in <AnnotatorTodoTab />.

        if (path?.includes('latest_verified_sample')) return // Error handled in <FeedbackPage />.

        if (message === 'User must be logged in to view this resource') {
          setErrorMessage('Problem accessing this resource. Please refresh the page or try re-logging in. If the issue persists, please try again later.')
        } else if (message === 'You do not have permission to perform this action') {
          setErrorMessage('You do not have permission to perform this action.')
        } else if (message?.includes(`No actions other than to change the deal's state is allowed.`)) {
          setErrorMessage(`This action cannot be performed as it would update a finalized ${t('deal')}.`)
        } else {
          setErrorMessage('Something went wrong performing your request.')
        }
      })
    }

    if (networkError) {
      if (networkError.name !== 'AbortError') console.error(`[Network error]: ${networkError}`)

      const isMutation = checkForMutation(operation)

      if (isMutation) setPendingMutationCount((pendingMutationCount: number) => pendingMutationCount - 1)
    }
  })

const httpLink = createHttpLink({ uri: API_ROOT + '/graphql' })

const mutationLink = (setPendingMutationCount: Dispatch<SetStateAction<number>>) =>
  new ApolloLink((operation, forward) => {
    const isMutation = checkForMutation(operation)

    if (isMutation) {
      setPendingMutationCount((pendingMutationCount: number) => pendingMutationCount + 1)

      // If this is an `editDataPointField` mutation, abort any pending `cciDealDataPointFields` queries.
      // Required to prevent stale data / race condition issues relating to the `refetchQueries` option in `ChecklistGptTab/ReviewEdits.tsx`.
      if (operation.operationName === 'editDataPointField') {
        const cciDealDataPointFieldsController = mutationLink.activeQueries.get('cciDealDataPointFields')

        if (cciDealDataPointFieldsController) {
          cciDealDataPointFieldsController.abort()
          mutationLink.activeQueries.delete('cciDealDataPointFields')
        }
      }
    } else if (operation.operationName === 'cciDealDataPointFields') {
      const controller = new AbortController()

      operation.setContext(({ fetchOptions = {} }) => ({ fetchOptions: { ...fetchOptions, signal: controller.signal } }))

      mutationLink.activeQueries.set('cciDealDataPointFields', controller) // Store the controller for the `cciDealDataPointFields` query.
    }

    return forward(operation).map(data => {
      if (isMutation) {
        setPendingMutationCount((pendingMutationCount: number) => pendingMutationCount - 1)
      } else if (operation.operationName === 'cciDealDataPointFields') {
        mutationLink.activeQueries.delete('cciDealDataPointFields') // Clean up the controller after query completes.
      }

      return data
    })
  }) as MutationControlLink

mutationLink.activeQueries = new Map()

// cache

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        assigned_to_me: relayStylePagination()
      }
    }
  }
})

// client

export const getApolloClient = (
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setExtendedErrorMessage: Dispatch<SetStateAction<string>>,
  setPendingMutationCount: Dispatch<SetStateAction<number>>
) =>
  new ApolloClient({
    link: from([authLink, mutationLink(setPendingMutationCount), errorLink(setErrorMessage, setExtendedErrorMessage, setPendingMutationCount), httpLink]),
    cache
  })
