import { ActionType } from '../../../graphql/codegen/schemas'
import { ActiveComponents, useCciMainContext } from '../CCI_Main'
import { ApolloQueryResult } from '@apollo/client'
import { CciDealDataPointFieldsQuery } from '../../../graphql/codegen/operations'
import { Features, Permissions, useUserAccess } from '../../../hooks/useUserAccess'
import { Row } from 'react-table'
import { Statuses } from '../../../utils/cci'
import { Steps } from './RightPanel/RightPanel_components/ChecklistGptTab/ConfigurationSteps'
import { size } from 'lodash'
import {
  useActionsToExtractionMethodMappingQuery,
  useCciDealDataPointFieldsQuery,
  useCciDocumentTypesQuery,
  useCciGroupsQuery,
  useExtractionMethodsQuery,
  useReorderChecklistItemMutation,
  useReorderGroupMutation
} from '../../../graphql/codegen/hooks'
import { useAppContext } from '../../../app'
import { useContextInit } from '../../../hooks/useContextInit'
import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'

// types

type _ActionTypeMap = { [key: string]: ActionType }

type _ChecklistConfigContext = {
  actionTypeMap: _ActionTypeMap
  actionTypeMapLoading?: boolean
  activeStep?: number
  areAllGroupsCollapsed: boolean
  areMultipleFieldsVisible: boolean
  docTypesLoading: boolean
  documentTypes?: any
  extractionMethodList: { label: string; value: string }[]
  fieldsData?: CciDealDataPointFieldsQuery
  filterGroup: (row: Row<_Row>, groupName: string) => boolean
  filterSearchTerm: (row: Row<_Row>) => boolean
  groupOptions?: any
  isBusy: boolean
  isCreateView: boolean
  isEditView?: boolean
  isGroupCollapsedMap: _IsCollapsedGroupMap
  isGroupsDataLoading: boolean
  isLoading: boolean
  isReorderingEnabled: boolean
  refetchFieldsData: () => Promise<ApolloQueryResult<CciDealDataPointFieldsQuery>>
  reorderField: (_: { variables: { data_point_field_id: string; move_after_data_point_field_id?: string; move_to_top_group?: string } }) => void
  reorderGroup: (_: { variables: { group_name: string; move_after_group_name: string | null } }) => void
  setActiveStep: Dispatch<SetStateAction<number | undefined>>
  setIsGroupCollapsedMap: Dispatch<SetStateAction<_IsCollapsedGroupMap>>
}

type _ChecklistConfigProviderProps = { children: ReactNode }
type _IsCollapsedGroupMap = { [key: string]: boolean }

export type _Row = {
  accountingImpactColumn: { displayAccountingImpact: boolean }
  actionTypeColumn: { actionTypeId: string }
  correctColumn: { correctSamples: number }
  defaultColumnColumn: { defaultFieldOnDashboard: boolean }
  descriptionColumn: { description: string }
  fieldNameColumn: { description: string; id: string; isDeleted: boolean; name: string; status: string }
  fieldTypeColumn: { fieldType: string }
  group: string
  id: string
  inReviewColumn: { inReviewSamples: number }
  name: string
  priority: number
  samplesColumn: { totalSamples: number }
  statusColumn: { mainStatus: Statuses | string }
  systemSourceColumn: { externalSource: string; source: string }
  visibilityColumn: { displayAnnotations: boolean; displayIfEmpty: boolean; displayOnChecklist: boolean }
}

// context

const ChecklistConfigContext = createContext<_ChecklistConfigContext | null>(null)

// hooks

export const useChecklistConfigContext = () => useContextInit(ChecklistConfigContext)

// components

export const ChecklistConfigProvider: FC<_ChecklistConfigProviderProps> = ({ children }) => {
  const { searchTerm, setIsSearchDataLoading, setSearchPlaceholder } = useAppContext()
  const { data: fieldsData, loading: isFieldsDataLoading, refetch: refetchFieldsData } = useCciDealDataPointFieldsQuery()
  const { activeComponent } = useCciMainContext()

  const [reorderField, { loading: isReorderFieldPending }] = useReorderChecklistItemMutation()
  const [reorderGroup, { loading: isReorderGroupPending }] = useReorderGroupMutation()
  const [activeStep, setActiveStep] = useState<number | undefined>(Steps.CONFIGURATION_DETAILS)
  const [documentTypes, setDocumentTypes] = useState<any>(false)
  const [groupOptions, setGroupOptions] = useState<any>(false)
  const [isGroupCollapsedMap, setIsGroupCollapsedMap] = useState<_IsCollapsedGroupMap>({})
  const [isSearching, setIsSearching] = useState(false)

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const hasLimitedEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD_LIMITED })
  const hasReorderingAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.REORDER })

  const isCreateView = activeComponent === ActiveComponents.CREATE_FIELD
  const isEditView = !isCreateView

  const { data: groupsData, loading: isGroupsDataLoading } = useCciGroupsQuery({ skip: !hasFullEditAccess && !hasLimitedEditAccess })
  const { data: docTypesData, loading: docTypesLoading } = useCciDocumentTypesQuery({ skip: !hasFullEditAccess && !hasLimitedEditAccess })

  const { data: actionsToExtractionMethodMappingData } = useActionsToExtractionMethodMappingQuery()
  const { data: extractionMethodsData } = useExtractionMethodsQuery()

  const extractionMethodList = useMemo(
    () => extractionMethodsData?.extraction_methods?.edges.map(item => ({ label: item?.node?.display_name || '', value: item?.node?.id || '' })) || [],
    [extractionMethodsData]
  )

  const actionTypeMap = useMemo<_ActionTypeMap>(
    () =>
      actionsToExtractionMethodMappingData?.actions_to_extraction_method_mapping?.reduce(
        (previous, current) => (current ? { ...previous, [current.id]: current } : previous),
        {}
      ) || {},
    [actionsToExtractionMethodMappingData]
  )

  const areAllGroupsCollapsed = useMemo(() => Object.values(isGroupCollapsedMap).every(value => value), [isGroupCollapsedMap])

  const areMultipleFieldsVisible = useMemo(() => {
    const expandedGroups = Object.entries(isGroupCollapsedMap).reduce<string[]>(
      (current, [groupName, isCollapsed]) => (!isCollapsed ? [...current, groupName] : current),
      []
    )

    const visibleFields = fieldsData?.cci_deal_data_point_fields?.filter(field => expandedGroups.includes(field?.group!)) || []

    return size(visibleFields) > 1
  }, [fieldsData?.cci_deal_data_point_fields, isGroupCollapsedMap])

  const filterGroup = useCallback(({ original: { group } }: Row<_Row>, groupName: string) => group === groupName, [])

  const filterSearchTerm = useCallback(
    ({ original: { name } }: Row<_Row>) => !searchTerm || name.toLowerCase().includes(searchTerm.toLowerCase()),
    [searchTerm]
  )

  const isBusy = useMemo(
    () => isFieldsDataLoading || isGroupsDataLoading || isReorderFieldPending || isReorderGroupPending,
    [isFieldsDataLoading, isGroupsDataLoading, isReorderFieldPending, isReorderGroupPending]
  )

  const isLoading = useMemo(() => isFieldsDataLoading || isGroupsDataLoading, [isFieldsDataLoading, isGroupsDataLoading])

  const isReorderingEnabled = useMemo(() => hasReorderingAccess && !searchTerm, [hasReorderingAccess, searchTerm])

  useMemo(() => {
    if (!isSearching && searchTerm) {
      setIsGroupCollapsedMap({})

      setIsSearching(true)
    } else if (!searchTerm) {
      setIsSearching(false)
    }
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setIsSearchDataLoading(isFieldsDataLoading), [isFieldsDataLoading, setIsSearchDataLoading])
  useEffect(() => setSearchPlaceholder(isFieldsDataLoading ? 'Loading checklist…' : 'Search checklist…'), [isFieldsDataLoading, setSearchPlaceholder])

  const context = useMemo<_ChecklistConfigContext>(
    () => ({
      actionTypeMap,
      activeStep,
      areAllGroupsCollapsed,
      areMultipleFieldsVisible,
      docTypesLoading,
      documentTypes,
      extractionMethodList,
      fieldsData,
      filterGroup,
      filterSearchTerm,
      groupOptions,
      isBusy,
      isCreateView,
      isEditView,
      isGroupCollapsedMap,
      isGroupsDataLoading,
      isLoading,
      isReorderingEnabled,
      refetchFieldsData,
      reorderField,
      reorderGroup,
      setActiveStep,
      setIsGroupCollapsedMap
    }),
    [
      actionTypeMap,
      activeStep,
      areAllGroupsCollapsed,
      areMultipleFieldsVisible,
      docTypesLoading,
      documentTypes,
      extractionMethodList,
      fieldsData,
      filterGroup,
      filterSearchTerm,
      groupOptions,
      isBusy,
      isCreateView,
      isEditView,
      isGroupCollapsedMap,
      isGroupsDataLoading,
      isLoading,
      isReorderingEnabled,
      refetchFieldsData,
      reorderField,
      reorderGroup
    ]
  )

  useEffect(() => setDocumentTypes(docTypesData?.cci_document_types?.map(item => ({ label: item?.name, value: item?.name }))), [docTypesData])
  useEffect(() => setGroupOptions(groupsData?.cci_groups?.map(item => ({ value: item, label: item }))), [groupsData])

  return <ChecklistConfigContext.Provider value={context}>{children}</ChecklistConfigContext.Provider>
}
