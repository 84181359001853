import { Box, TextField, Typography } from '@mui/material'
import { FieldTypes } from '../CreateInputs/FieldTypeInput'
import { getDataTableMatchRuleLabel, getMatchRuleLabel } from '../../../../../../../utils/cci'
import { sortBy } from 'lodash'
import React, { FC, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

export enum DataTableMatchRuleLabels {
  TABLE_MATCHING = 'Table Matching',
  TABLE_MATCHING_ALPHA = 'Table Matching Alpha',
  TABLE_MATCHING_BETA = 'Table Matching Beta',
  TABLE_MATCHING_GAMMA = 'Table Matching Gamma',
  TABLE_MATCHING_LEGACY = 'Legacy Table Matching',
  TABLE_MATCHING_O1_2024_12_17 = 'Table Matching O1 2024-12-17',
  TABLE_MATCHING_SONNET_V2_OCT_2024 = 'Table Matching Sonnet V2 Oct 2024'
}

export enum DataTableMatchRules {
  TABLE_MATCHING = 'TABLE_MATCHING_LVM',
  TABLE_MATCHING_ALPHA = 'TABLE_MATCHING_ALPHA',
  TABLE_MATCHING_BETA = 'TABLE_MATCHING_BETA',
  TABLE_MATCHING_GAMMA = 'TABLE_MATCHING_GAMMA',
  TABLE_MATCHING_LEGACY = 'TABLE_MATCHING_LEGACY',
  TABLE_MATCHING_O1_2024_12_17 = 'TABLE_MATCHING_O1_2024_12_17',
  TABLE_MATCHING_SONNET_V2_OCT_2024 = 'TABLE_MATCHING_SONNET_V2_OCT_2024'
}

type _MatchRuleProps = {
  fieldType?: string
  handleData?: (type: string, value: any) => void
  isCreateViewReadOnly?: boolean
  isEditView?: boolean
  rule?: string
}

export enum MatchRuleLabels {
  LLM_ADDRESS_MATCH = 'LLM Address Match',
  LLM_COMPANY_MATCH = 'LLM Company Match',
  LLM_CUSTOM_TEXT_MATCH = 'LLM Custom Text Match',
  STRICT_EQUALITY = 'Strict Equality'
}

export enum MatchRules {
  LLM_ADDRESS_MATCH = 'LLM_ADDRESS_MATCH',
  LLM_COMPANY_MATCH = 'LLM_COMPANY_MATCH',
  LLM_CUSTOM_TEXT_MATCH = 'LLM_CUSTOM_TEXT_MATCH',
  STRICT_EQUALITY = 'STRICT_EQUALITY'
}

// components

export const MatchRule: FC<_MatchRuleProps> = ({ fieldType, handleData = () => undefined, isCreateViewReadOnly = false, isEditView = false, rule }) => {
  const matchRuleOptions = useMemo(
    () =>
      sortBy(
        Object.entries(fieldType === FieldTypes.DATA_TABLE ? DataTableMatchRules : MatchRules).map(([key, value]) => ({
          label:
            fieldType === FieldTypes.DATA_TABLE
              ? DataTableMatchRuleLabels[key as keyof typeof DataTableMatchRules]
              : MatchRuleLabels[key as keyof typeof MatchRules],
          value
        })),
        'label'
      ),
    [fieldType]
  )

  if (isCreateViewReadOnly) {
    return (
      <Box className={css.inputWrapper}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mr: 2, width: 164 }}>Match Rule</Typography>

        <TextField
          InputProps={{ style: { fontSize: 14 } }}
          disabled
          size="small"
          sx={{ fontSize: 14 }}
          value={fieldType === FieldTypes.DATA_TABLE ? getDataTableMatchRuleLabel(rule) : getMatchRuleLabel(rule)}
        />
      </Box>
    )
  }

  if (isEditView) {
    return (
      <Box display="flex" mb={1}>
        <Typography fontSize={14} fontWeight={600} width={180}>
          Match Rule
        </Typography>

        <Typography fontSize={14}>
          {Object.values(DataTableMatchRules).includes(rule as DataTableMatchRules) ? getDataTableMatchRuleLabel(rule) : getMatchRuleLabel(rule)}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={css.inputWrapper}>
      <Typography component="label" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Match Rule
      </Typography>

      <SelectInput
        isClearable
        onChange={(item: { value: string }) => handleData('match_rule', item?.value)}
        options={matchRuleOptions}
        placeholder={fieldType === FieldTypes.DATA_TABLE ? getDataTableMatchRuleLabel(rule) : getMatchRuleLabel(rule)}
        value={rule}
      />
    </Box>
  )
}
