import * as pdfjs from '../../../../pdfjs-dist-local'
import { Annotation } from '../../graphql/codegen/schemas'
import { Box, alpha } from '@mui/material'
import { FeedbackModes, Highlight, PdfHighlighter as ReactPdfHighlighter } from '@klarity/pdf-highlighter'
import { HighlightPopup } from '../PDFHighlighterDoc/HighlightPopup'
import { captureError } from '../../utils/sentry'
import { getBoundingRect } from '../../utils/pdfHighlighterUtils'
import { getPdfDocument } from '../../utils/documentApiUtils'
import {
  klarityAnnotationToHighlighterFormat,
  parseIdFromHash,
  positionToFeedbackHighlightPositionInput,
  removeDataPointSearchParam
} from '../PDFHighlighterDoc/utils'
import { red } from '@mui/material/colors'
import { snakeCase } from 'lodash'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import { useHistory } from 'react-router-dom'
import DocumentPlaceholder from '../DocumentPlaceholder'
import PdfjsWorker from '../../../../pdfjs-dist-local/build/pdf.worker.entry'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'

// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorker

// types

type _FeedbackPDFHighlighterProps = { onDocumentReady: () => void; onPageChange: Dispatch<SetStateAction<number>>; scale: 'auto' | number }

// components

export const FeedbackPDFHighlighter: FC<_FeedbackPDFHighlighterProps> = ({ onDocumentReady, onPageChange, scale }) => {
  const { dataPoint, document, feedbackHighlightList, isDocumentReady, isSampleValueCorrect, latestVerifiedSample, setFeedbackHighlightList } =
    useFeedbackPageContext()

  const [pdfDocumentProxy, setPdfDocumentProxy] = useState<any>(null)
  const history = useHistory()

  const feedbackMode = isSampleValueCorrect ? FeedbackModes.DISABLED : FeedbackModes.ENABLED

  const scrollRef = useRef<(highlight: any) => void>()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const highlights = useMemo(
    () => document?.annotations?.edges?.map((edge: any) => klarityAnnotationToHighlighterFormat(edge?.node as Annotation)) || [],
    [document]
  )

  // functions

  const clearHash = useCallback(() => history.replace({ pathname: window.location.pathname, search: window.location.search }), [history])

  const onSelectionFinished = useCallback(
    (position, _, hideTipAndSelection, transformSelection) => {
      const internal_name = snakeCase(dataPoint?.data_point_field?.name)
      const positions = [positionToFeedbackHighlightPositionInput(position)]
      const feedbackHighlight = { internal_name, positions }

      setFeedbackHighlightList(previous => [...previous, feedbackHighlight])
      hideTipAndSelection()
      transformSelection()
    },
    [dataPoint, setFeedbackHighlightList]
  )

  const scrollToHighlight = useCallback(
    targetHighlight => {
      const foundHighlight = highlights.find(highlight => highlight !== null && highlight.id === targetHighlight.id)

      scrollRef?.current?.(foundHighlight)
    },
    [highlights]
  )

  const scrollToHighlightFromHash = useCallback(() => {
    const id = parseIdFromHash()

    if (id) scrollToHighlight({ id })
  }, [scrollToHighlight])

  // effects

  useEffect(() => {
    const setDocument = async () => {
      if (document?.id) {
        try {
          // Convert the document from base64 encoding to a bytestring for pdfjs (use cMapUrl and cMapPacked to handle East Asian characters)
          const base64EncodedDocument = await getPdfDocument(document.id, 'PDF')
          const data = atob(base64EncodedDocument.split('base64,')[1])
          const doc = await pdfjs.getDocument({ data, cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`, cMapPacked: true }).promise

          setPdfDocumentProxy(doc)
        } catch (error) {
          captureError(error)
        }
      }
    }

    setPdfDocumentProxy(null)
    setDocument()
  }, [document])

  useEffect(() => {
    scrollToHighlightFromHash()

    window.addEventListener('hashchange', scrollToHighlightFromHash)

    return () => window.removeEventListener('hashchange', scrollToHighlightFromHash)
  }, [highlights, scrollToHighlightFromHash])

  // Automatically scroll to the first highlight when the document is ready and in focus, if the hash is not already set.
  // The document must be in focus; otherwise, the Arc browser will not render the first highlight (for unknown reasons).
  useEffect(() => {
    if (window.document.location.hash) return

    const setHashToFirstHighlight = () => {
      if (latestVerifiedSample?.data_point_annotations?.edges?.[0]?.node?.id) {
        window.document.location.hash = `#highlight-${latestVerifiedSample.data_point_annotations.edges[0].node.id}`

        window.removeEventListener('focus', setHashToFirstHighlight)
      }
    }

    if (isDocumentReady) {
      if (window.document.hasFocus()) {
        setHashToFirstHighlight()
      } else {
        window.addEventListener('focus', setHashToFirstHighlight)
      }
    }

    return () => window.removeEventListener('focus', setHashToFirstHighlight)
  }, [isDocumentReady, latestVerifiedSample])

  // render

  return (
    <Box ref={wrapperRef} sx={{ height: '100%', overflow: 'hidden', maxWidth: '100%' }}>
      {(!pdfDocumentProxy || !highlights) && (
        <Box>
          <DocumentPlaceholder />
        </Box>
      )}

      {pdfDocumentProxy && highlights && (
        <>
          <ReactPdfHighlighter
            feedbackHighlights={feedbackHighlightList}
            feedbackMode={feedbackMode}
            highlights={highlights}
            isBusyMap={{}}
            onDocumentReady={onDocumentReady}
            onPageChange={onPageChange}
            onScrollChange={() => {
              removeDataPointSearchParam()
              clearHash()
            }}
            onSelectionFinished={onSelectionFinished}
            pdfDocument={pdfDocumentProxy}
            pdfScaleValue={scale.toString()}
            renderFeedbackHighlight={highlight => <FeedbackHighlight highlight={highlight} key={highlight.id} />}
            renderHighlight={(highlight, isHighlightPopupDismissedViaClick, isScrolledTo) => (
              <Highlight
                highlight={highlight}
                isScrolledTo={!isHighlightPopupDismissedViaClick && isScrolledTo}
                key={highlight.id}
                renderPopup={highlight =>
                  !isHighlightPopupDismissedViaClick && <HighlightPopup deleteAnnotation={null} highlight={highlight} isScrolledTo={isScrolledTo} />
                }
                showAllTags={false}
              />
            )}
            scrollRef={triggerScrollTo => {
              scrollRef.current = triggerScrollTo
              scrollToHighlightFromHash()
            }}
          />
        </>
      )}
    </Box>
  )
}

export const FeedbackHighlight = ({ highlight }: any) => {
  const boundingRect = useMemo(() => getBoundingRect(highlight.position.rects), [highlight.position.rects])

  return (
    <Box
      sx={{
        bgcolor: alpha(red[200], 0.1),
        border: '2px dotted',
        borderColor: red[700],
        position: 'absolute',
        transition: 'background-color 150ms ease-in-out, border-color 150ms ease-in-out',
        '&:hover .MuiSvgIcon-root': { display: 'block' },
        ...boundingRect
      }}
    />
  )
}
