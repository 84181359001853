import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { TooltipInfo } from '../TooltipInfo'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { useFieldValuesContext } from '../FieldValuesProvider'
import React, { FC, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'

// constants

const FIELD = 'extraction_method_enabled'

const LABEL = 'Enable extraction automation'

// components

export const ExtractionAutomationSwitch: FC = () => {
  const { isCreateView, isEditView } = useChecklistConfigContext()
  const { selectedItem } = useCciMainContext()
  const { fieldValues, updateFieldValue } = useFieldValuesContext()

  const initialExtractionConfiguration = useMemo(() => JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}'), [selectedItem])

  const [isSwitchChecked, setIsSwitchChecked] = useState(isCreateView || Boolean(initialExtractionConfiguration.is_enabled))

  const isSwitchDisabled = isEditView && isEmpty(fieldValues?.extraction_method_id || initialExtractionConfiguration.extraction_method_id)

  const handleChange = (_: unknown, isEnabled: boolean) => {
    setIsSwitchChecked(isEnabled)

    updateFieldValue(FIELD, isEnabled !== initialExtractionConfiguration.is_enabled ? isEnabled : null)
  }

  useEffect(() => {
    if (isCreateView) updateFieldValue(FIELD, true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={css.inputWrapper}>
      <FormControlLabel
        control={<Switch checked={isSwitchChecked} disabled={isSwitchDisabled} onChange={handleChange} />}
        label={
          <Typography variant="body2">
            {LABEL}

            {isSwitchDisabled && <TooltipInfo tooltipTitle="Select an AI Provider to enable extraction automation." />}
          </Typography>
        }
      />
    </Box>
  )
}
