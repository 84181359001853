import { ExtractionPromptFragmentTemplate, PromptFragment } from '../../../../../../../graphql/codegen/schemas'
import { FormLabel, TextField, Typography } from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { isEmpty, isEqual } from 'lodash'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { useExtractionMethodsQuery } from '../../../../../../../graphql/codegen/hooks'
import { useFieldValuesContext } from '../FieldValuesProvider'
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'

// components

export const FragmentsInput: FC = () => {
  const { isCreateView } = useChecklistConfigContext()
  const { selectedItem } = useCciMainContext()
  const { data: extractionMethodsData } = useExtractionMethodsQuery()
  const { fieldValues, updateFieldValue } = useFieldValuesContext()

  const extractionMethodId = useMemo(
    () => fieldValues?.extraction_method_id || JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}').extraction_method_id,
    [fieldValues, selectedItem]
  )

  const savedPromptFragments = useMemo(
    () =>
      (selectedItem?.prompt_fragments as PromptFragment[])?.map(({ content, prompt_fragment_template }) => ({
        content,
        prompt_fragment_template_id: prompt_fragment_template?.id
      })) || [],
    [selectedItem?.prompt_fragments]
  )

  const [promptFragments, setPromptFragments] = useState(savedPromptFragments)

  useEffect(() => {
    if (!isEqual(promptFragments, savedPromptFragments)) {
      updateFieldValue('prompt_fragments', promptFragments)
    }
  }, [promptFragments, savedPromptFragments, updateFieldValue])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, prompt_fragment_template_id: string) => {
      setPromptFragments(current => {
        let newPromptFragments = [...current]

        if (isEmpty(newPromptFragments)) {
          newPromptFragments = [{ content: event.target.value, prompt_fragment_template_id }]
        } else {
          newPromptFragments[index] = { ...newPromptFragments[index], content: event.target.value, prompt_fragment_template_id }
        }

        return newPromptFragments
      })
    },
    [setPromptFragments]
  )

  const displayPromptFragments = useMemo(() => {
    let inputs = promptFragments.reduce<ExtractionPromptFragmentTemplate[]>((previous, current) => {
      const input = extractionMethodsData?.extraction_methods?.edges
        .flatMap(edge => edge?.node?.active_prompt_templates?.flatMap(active_prompt_template => active_prompt_template?.prompt_fragment_templates))
        .find(promptFragmentTemplate => promptFragmentTemplate?.id === current.prompt_fragment_template_id)

      return input ? [...previous, input as ExtractionPromptFragmentTemplate] : previous
    }, [])

    if (isEmpty(inputs)) {
      inputs = (extractionMethodsData?.extraction_methods?.edges
        .filter(edge => edge?.node?.id === extractionMethodId)
        .flatMap(edge => edge?.node?.active_prompt_templates?.flatMap(active_prompt_template => active_prompt_template?.prompt_fragment_templates))
        .filter(promptFragmentTemplate => (isCreateView ? promptFragmentTemplate?.fragment_type === 'user_input' : !promptFragmentTemplate?.is_hidden)) ||
        []) as ExtractionPromptFragmentTemplate[]
    }

    return inputs
  }, [extractionMethodsData, extractionMethodId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isEmpty(displayPromptFragments)) return null

  return (
    <>
      {displayPromptFragments.map((displayPromptFragment, index) => (
        <FormLabel key={index} sx={{ display: 'block', mb: 0.5, mt: 2 }}>
          <Typography sx={{ color: common.black, fontSize: 14, fontWeight: 700 }}>{displayPromptFragment.display_question}</Typography>

          <Typography sx={{ color: common.black, fontSize: 12, mb: 0.5 }}>{displayPromptFragment.description}</Typography>

          <TextField
            fullWidth
            inputProps={{ sx: { fontSize: 14, '&.Mui-disabled': { color: grey[800], WebkitTextFillColor: `${grey[800]} !important` } } }}
            multiline
            onChange={event => handleChange(event, index, displayPromptFragment.id)}
            placeholder={displayPromptFragment.display_guidelines || ''}
            sx={{ '& .Mui-disabled': { backgroundColor: grey[100] } }}
            value={promptFragments.find(({ prompt_fragment_template_id }) => displayPromptFragment.id === prompt_fragment_template_id)?.content || ''}
          />
        </FormLabel>
      ))}
    </>
  )
}
