import { ActiveComponents, useCciMainContext } from '../CCI_Main'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem } from '@mui/material'
import { ButtonLoading } from '../../../components/ButtonLoading'
import { CciGroupsDocument, useCciGroupsQuery, useDeleteDataPointFieldsMutation, useMoveDataPointFieldsMutation } from '../../../graphql/codegen/hooks'
import { ConfigNavLinkLabels } from '../../../components/NavBar/ConfigMenu'
import { Features, Permissions, useHasChecklistGptAccess, useUserAccess } from '../../../hooks/useUserAccess'
import { Opening, useOpening } from '@hoologic/use-opening'
import { Search } from '../../../components/Search'
import { Z_INDEX_OVERLAY } from '../../../utils/styleUtils'
import { blue } from '@mui/material/colors'
import { isEmpty, size } from 'lodash'
import { useLocation } from 'react-router-dom'
import React, { FC, useCallback, useMemo, useState } from 'react'

// types

type _BulkDeleteConfirmationProps = { opening: Opening }
type _BulkMoveConfirmationProps = { groupName: string; opening: Opening }

// constants

const HEIGHT = 72
const MARGIN_TOP = -2

export const CONFIG_HEADER_HEIGHT = HEIGHT + MARGIN_TOP * 8

// components

const BulkActions: FC = () => {
  const { setCheckedFields } = useCciMainContext()

  return (
    <Chip
      label={<ChipLabel />}
      onDelete={() => setCheckedFields([])}
      size="medium"
      sx={{ bgcolor: blue[50], borderRadius: 8, px: 'unset', '.MuiChip-label': { fontWeight: 600 } }}
    />
  )
}

const ChipLabel: FC = () => {
  const { checkedFields } = useCciMainContext()
  const { data: groupsData } = useCciGroupsQuery()
  const bulkDeleteOpening = useOpening()
  const bulkMoveMenuOpening = useOpening()
  const bulkMoveOpening = useOpening()
  const [groupName, setGroupName] = useState<string>()
  const hasDeleteFieldPermission = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.DELETE_FIELD })
  const hasReorderPermission = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.REORDER })

  const selectGroupName = useCallback(
    (groupName: string) => {
      setGroupName(groupName)
      bulkMoveMenuOpening.close()
      bulkMoveOpening.open()
    },
    [bulkMoveMenuOpening, bulkMoveOpening]
  )

  if (!groupsData) return null

  return (
    <>
      <Box sx={{ alignItems: 'baseline', display: 'flex', gap: 2, mx: 1 }}>
        {`${size(checkedFields)} field${size(checkedFields) === 1 ? '' : 's'} selected`}

        {hasReorderPermission && (
          <Box component="span" onClick={bulkMoveMenuOpening.open} sx={{ cursor: 'pointer' }}>
            Move
          </Box>
        )}

        {hasDeleteFieldPermission && (
          <Box component="span" onClick={bulkDeleteOpening.open} sx={{ cursor: 'pointer' }}>
            Delete
          </Box>
        )}
      </Box>

      {bulkDeleteOpening.isOpen && <BulkDeleteConfirmation opening={bulkDeleteOpening} />}

      <Menu anchorEl={bulkMoveMenuOpening.anchor} onClose={bulkMoveMenuOpening.close} open={bulkMoveMenuOpening.isOpen}>
        {groupsData.cci_groups!.map(groupName => (
          <MenuItem key={groupName} onClick={() => selectGroupName(groupName!)} sx={{ fontSize: 13, fontWeight: 600 }}>
            {groupName}
          </MenuItem>
        ))}
      </Menu>

      {bulkMoveOpening.isOpen && groupName && <BulkMoveConfirmation groupName={groupName} opening={bulkMoveOpening} />}
    </>
  )
}

const BulkDeleteConfirmation: FC<_BulkDeleteConfirmationProps> = ({ opening }) => {
  const { checkedFields, setCheckedFields } = useCciMainContext()

  const [deleteDataPointFields, { loading: isLoading }] = useDeleteDataPointFieldsMutation({
    awaitRefetchQueries: true,
    onCompleted: () => {
      opening.close()
      setCheckedFields([])
    },
    refetchQueries: [{ query: CciGroupsDocument }]
  })

  const handleBulkDeleteDataPointFields = useCallback(() => {
    deleteDataPointFields({ variables: { dataPointFieldIds: checkedFields } })
  }, [checkedFields, deleteDataPointFields])

  return (
    <Dialog onClose={opening.close} open={opening.isOpen} sx={{ zIndex: Z_INDEX_OVERLAY }}>
      <DialogTitle>Delete Fields</DialogTitle>

      <DialogContent>
        Are you sure you want to delete the {size(checkedFields) === 1 ? 'selected field' : `${size(checkedFields)} selected fields`}?
      </DialogContent>

      <DialogActions>
        <Button color="tertiary" disabled={isLoading} onClick={opening.close} variant="outlined">
          Cancel
        </Button>

        <Button color="error" disabled={isLoading} onClick={handleBulkDeleteDataPointFields} variant="contained">
          {isLoading && <ButtonLoading />}

          <>Delete</>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const BulkMoveConfirmation: FC<_BulkMoveConfirmationProps> = ({ groupName, opening }) => {
  const { checkedFields, setCheckedFields } = useCciMainContext()

  const [moveDataPointFields, { loading: isLoading }] = useMoveDataPointFieldsMutation({
    awaitRefetchQueries: true,
    onCompleted: () => {
      opening.close()
      setCheckedFields([])
    },
    refetchQueries: [{ query: CciGroupsDocument }]
  })

  const handleBulkMoveDataPointFields = useCallback(() => {
    moveDataPointFields({ variables: { dataPointFieldIds: checkedFields, groupName } })
  }, [checkedFields, groupName, moveDataPointFields])

  return (
    <Dialog onClose={opening.close} open={opening.isOpen} sx={{ zIndex: Z_INDEX_OVERLAY }}>
      <DialogTitle>Move Fields</DialogTitle>

      <DialogContent>
        <>Are you sure you want to move the {size(checkedFields) === 1 ? 'selected field' : `${size(checkedFields)} selected fields`} to the </>

        <Box component="span" sx={{ fontWeight: 600 }}>
          “{groupName}”
        </Box>

        <> section?</>
      </DialogContent>

      <DialogActions>
        <Button color="tertiary" disabled={isLoading} onClick={opening.close} variant="outlined">
          Cancel
        </Button>

        <Button disabled={isLoading} onClick={handleBulkMoveDataPointFields} variant="contained">
          {isLoading && <ButtonLoading />}

          <>Move</>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const Header: FC = () => {
  const location = useLocation()
  const { checkedFields, fieldOverlay, setActiveComponent, setCheckedFields } = useCciMainContext()
  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const hasChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })
  const hasLimitedChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD_LIMITED })

  const buttonSx = useMemo(
    () => ({ visibility: hasChecklistCreateAccess || hasLimitedChecklistCreateAccess ? 'visible' : 'hidden' }),
    [hasChecklistCreateAccess, hasLimitedChecklistCreateAccess]
  )

  const isShowBulkActions = useMemo(() => !isEmpty(checkedFields), [checkedFields])

  const handleClick = useCallback(() => {
    fieldOverlay.open()
    setActiveComponent(ActiveComponents.CREATE_FIELD)
    setCheckedFields([])
  }, [fieldOverlay, setActiveComponent, setCheckedFields])

  const currentConfigLabel = ConfigNavLinkLabels[location.pathname] || 'Configuration'
  const showAddButton = location.pathname === '/config' && hasChecklistGptAccess

  return (
    <Box sx={{ alignItems: 'center', borderBottom: 1, borderColor: 'divider', display: 'flex', height: HEIGHT, mt: MARGIN_TOP, whiteSpace: 'nowrap', px: 2 }}>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, justifyContent: 'space-between', width: '50%' }}>
        <Box sx={{ fontSize: 30, fontWeight: 600 }}>{currentConfigLabel}</Box>
        <Box sx={{ flex: '0 1 600px' }}>
          <Search onChange={() => setCheckedFields([])} />
        </Box>
      </Box>

      {isShowBulkActions && (
        <Box sx={{ ml: 2 }}>
          <BulkActions />
        </Box>
      )}

      {showAddButton && (
        <Box sx={{ ml: 'auto' }}>
          <Button onClick={handleClick} sx={buttonSx} variant="contained">
            Add field
          </Button>
        </Box>
      )}
    </Box>
  )
}
