import { AccountingImpactCell } from './cells/AccountingImpactCell'
import { ActionTypeCell } from './cells/ActionTypeCell'
import { Box, Checkbox, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { CONFIG_HEADER_HEIGHT } from '../Header'
import { Column, HeaderGroup, Row, useTable } from 'react-table'
import { CorrectCell } from './cells/CorrectCell'
import { DefaultColumnCell } from './cells/DefaultColumnCell'
import { DescriptionCell } from './cells/DescriptionCell'
import { DndContext, DragEndEvent, DragMoveEvent, DragOverlay, DragStartEvent, pointerWithin, useDraggable, useDroppable } from '@dnd-kit/core'
import { Features, Permissions, useUserAccess } from '../../../../hooks/useUserAccess'
import { FieldImport } from './FieldImport'
import { FieldNameCell } from './cells/FieldNameCell'
import { FieldOverlay } from './FieldOverlay'
import { FieldTypeCell } from './cells/FieldTypeCell'
import { HEADER_HEIGHT, KLARITY_BLUE, KLARITY_BLUE_BACKGROUND, Z_INDEX_DRAG } from '../../../../utils/styleUtils'
import { InReviewCell } from './cells/InReviewCell'
import { SamplesCell } from './cells/SamplesCell'
import { StatusCell } from './cells/StatusCell'
import { SystemSourceCell } from './cells/SystemSourceCell'
import { VisibilityCell } from './cells/VisibilityCell'
import { _Row, useChecklistConfigContext } from '../ChecklistConfigProvider'
import { arrayMove } from '@dnd-kit/sortable'
import { common, grey } from '@mui/material/colors'
import { groupDataPointFields } from '../../../../utils/dataPointUtils'
import { isEmpty, isEqual, size, sortBy } from 'lodash'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useCciMainContext } from '../../CCI_Main'
import { useContextInit } from '../../../../hooks/useContextInit'
import { useRefCallback } from '../../../../hooks/useRefCallback'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { FC, RefObject, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

// types

enum CheckboxStates {
  CHECKED = 1,
  INDETERMINATE,
  UNCHECKED
}

type _ChecklistTableContext = {
  areMultipleGroups: boolean
  firstRowRefCallback: (node: HTMLTableRowElement) => void
  hasDeleteFieldPermission: boolean
  hasReorderPermission: boolean
  prepareRow: (row: Row<_Row>) => void
  reorderLine: number | null
  rows: Row<_Row>[]
  top: number
}

type _FieldRowProps = { fieldId: string; row: Row<_Row> }
type _GroupAndFieldsRowsProps = { groupName: string }
type _GroupRowProps = { groupName: string }
type _HeaderRowProps = { headerGroup: HeaderGroup<_Row> }

enum ReorderTypes {
  FIELD = 1,
  GROUP
}

// constants

const CELL_SX = { borderRight: `1px solid ${grey[300]}`, height: 44, maxWidth: 300, px: 1, py: 0 } as const

export const CHECKBOX_SX = {
  m: -0.375,
  '&.MuiCheckbox-root': { color: grey[400], transition: 'color 100ms', '&:hover': { color: grey[600] }, '&.Mui-checked': { color: 'primary.main' } }
} as const

const SAMPLES_COLUMNS = ['Samples:', 'In Review', 'Correct']

const COLUMNS: Column<_Row>[] = [
  {
    Header: 'Field Name',
    accessor: 'fieldNameColumn',
    Cell: ({ value: { description, id, isDeleted, name, status } }) => (
      <FieldNameCell description={description} id={id} isDeleted={isDeleted} name={name} status={status} />
    )
  },
  { Header: 'Status', accessor: 'statusColumn', Cell: ({ value: { mainStatus } }) => <StatusCell mainStatus={mainStatus} /> },
  {
    Header: 'System Source',
    accessor: 'systemSourceColumn',
    Cell: ({ value: { externalSource, source } }) => <SystemSourceCell externalSource={externalSource} source={source} />
  },
  {
    Header: SAMPLES_COLUMNS[0],
    accessor: 'samplesColumn',
    Cell: ({ value: { totalSamples } }) => <SamplesCell totalSamples={totalSamples} />
  },
  {
    Header: SAMPLES_COLUMNS[1],
    accessor: 'inReviewColumn',
    Cell: ({ value: { inReviewSamples } }) => <InReviewCell inReviewSamples={inReviewSamples} />
  },
  {
    Header: SAMPLES_COLUMNS[2],
    accessor: 'correctColumn',
    Cell: ({ value: { correctSamples } }) => <CorrectCell correctSamples={correctSamples} />
  },
  {
    Header: 'Field Type',
    accessor: 'fieldTypeColumn',
    Cell: ({ value: { fieldType } }) => <FieldTypeCell fieldType={fieldType} />
  },
  {
    Header: 'Action Type',
    accessor: 'actionTypeColumn',
    Cell: ({ value: { actionTypeId } }) => <ActionTypeCell actionTypeId={actionTypeId} />
  },
  {
    Header: 'Description',
    accessor: 'descriptionColumn',
    Cell: ({ value: { description } }) => <DescriptionCell description={description} />
  },
  {
    Header: 'Visibility',
    accessor: 'visibilityColumn',
    Cell: ({ value: { displayAnnotations, displayIfEmpty, displayOnChecklist } }) => (
      <VisibilityCell displayAnnotations={displayAnnotations} displayIfEmpty={displayIfEmpty} displayOnChecklist={displayOnChecklist} />
    )
  },
  {
    Header: 'Accounting Impact Flag',
    accessor: 'accountingImpactColumn',
    Cell: ({ value: { displayAccountingImpact } }) => <AccountingImpactCell displayAccountingImpact={displayAccountingImpact} />
  },
  {
    Header: 'Default Column',
    accessor: 'defaultColumnColumn',
    Cell: ({ value: { defaultFieldOnDashboard } }) => <DefaultColumnCell defaultFieldOnDashboard={defaultFieldOnDashboard} />
  }
]

const FIRST_CELL_SX = { borderRight: `2px solid ${grey[300]}`, height: 44, left: 0, pl: 6.25, position: 'sticky', py: 1, zIndex: 2 } as const

// context

const ChecklistTableContext = createContext<_ChecklistTableContext | null>(null)

// functions

const calculateReorderPosition = (event: DragEndEvent, tableRef: RefObject<HTMLTableElement>) => {
  const { active, over } = event

  if (!active.rect.current.translated || !over) return null

  const dropRect = over.rect
  const dragCenterY = active.rect.current.translated.top + active.rect.current.translated.height / 2
  const isPlacedBefore = dragCenterY < dropRect.top + dropRect.height / 2

  return {
    isPlacedBefore,
    reorderLine: (isPlacedBefore ? dropRect.top : dropRect.bottom) - HEADER_HEIGHT - CONFIG_HEADER_HEIGHT + (tableRef.current?.scrollTop || 0) - 1
  }
}

// hooks

const useChecklistTableContext = () => useContextInit(ChecklistTableContext)

// components

export const Checklist: FC = () => {
  const { fieldOverlay, setDocumentTypesField } = useCciMainContext()

  const { fieldsData, isBusy, isGroupCollapsedMap, isLoading, refetchFieldsData, reorderField, reorderGroup, setIsGroupCollapsedMap } =
    useChecklistConfigContext()

  const tableRef = useRef<HTMLTableElement>(null)
  const [dragOverlay, setDragOverlay] = useState<string | null>(null)
  const [fields, setFields] = useState<_Row[]>([])
  const [firstRowElement, firstRowRefCallback] = useRefCallback()
  const [groupNames, setGroupNames] = useState<string[]>([])
  const [isRefetching, setIsRefetching] = useState(false)
  const [reorderLine, setReorderLine] = useState<number | null>(null)
  const [top, setTop] = useState(0)
  const hasDeleteFieldPermission = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.DELETE_FIELD })
  const hasReorderPermission = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.REORDER })

  const handleFieldImportSuccess = async () => {
    setIsRefetching(true)

    await refetchFieldsData()

    setIsRefetching(false)
  }

  useMemo(
    () =>
      setFields(
        fieldsData?.cci_deal_data_point_fields
          ?.filter(field => field?.group !== 'Aggregates')
          .map(field => ({
            accountingImpactColumn: { displayAccountingImpact: field?.display_accounting_impact! },
            actionTypeColumn: { actionTypeId: field?.action_type?.id! },
            correctColumn: { correctSamples: field?.correct_samples! },
            defaultColumnColumn: { defaultFieldOnDashboard: field?.default_field_on_dashboard! },
            descriptionColumn: { description: field?.field_semantics! },
            fieldNameColumn: {
              description: field?.description!,
              id: field?.id!,
              isDeleted: field?.is_deleted!,
              name: field?.name!,
              status: field?.main_status!
            },
            fieldTypeColumn: { fieldType: field?.field_type! },
            group: field?.group!,
            groupPriority: field?.group_priority!,
            id: field?.id!,
            inReviewColumn: { inReviewSamples: field?.in_review_samples! },
            name: field?.name!,
            priority: field?.priority!,
            samplesColumn: { totalSamples: field?.total_samples! },
            statusColumn: { mainStatus: field?.main_status! },
            systemSourceColumn: { externalSource: field?.external_source!, source: field?.source! },
            visibilityColumn: {
              displayAnnotations: field?.display_annotations!,
              displayIfEmpty: field?.display_if_empty!,
              displayOnChecklist: field?.display_on_checklist!
            }
          }))
          .sort((a, b) =>
            a?.groupPriority! > b?.groupPriority! ? 1 : a?.groupPriority! === b?.groupPriority! ? (a?.priority! > b?.priority! ? 1 : -1) : -1
          ) || []
      ),
    [fieldsData]
  )

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({ columns: COLUMNS, data: fields })

  const areMultipleGroups = useMemo(() => size(groupNames) > 1, [groupNames])
  const firstRowElementHeight = useMemo(() => firstRowElement?.clientHeight || 0, [firstRowElement])

  useMemo(() => {
    const groups = groupDataPointFields(fieldsData?.cci_deal_data_point_fields || [])

    const current = sortBy(Object.keys(groups), key => groups[key][0]?.group_priority)

    setGroupNames(previous => (isEqual(current, previous) ? previous : current))
  }, [fieldsData])

  useMemo(() => {
    if (!firstRowElement) return

    setTop(firstRowElementHeight)
  }, [firstRowElement, firstRowElementHeight])

  useEffect(
    () => setDocumentTypesField(fieldsData?.cci_deal_data_point_fields?.find(field => field?.internal_mapping?.includes('document_type'))),
    [fieldsData?.cci_deal_data_point_fields, setDocumentTypesField]
  )

  useEffect(() => {
    if (isBusy || isEmpty(groupNames) || !isEmpty(isGroupCollapsedMap)) return

    setIsGroupCollapsedMap(groupNames.reduce((acc, groupName) => ({ ...acc, [groupName]: false }), {}))
  }, [groupNames, isBusy, isGroupCollapsedMap, setIsGroupCollapsedMap])

  const handleDragCancel = useCallback(() => {
    setReorderLine(null)

    if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
  }, [])

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) =>
      setDragOverlay(active.data.current?.type === ReorderTypes.FIELD ? fields.find(field => field.id === active.id)?.name! : (active.id as string)),
    [fields]
  )

  const handleFieldDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      setReorderLine(null)

      if (!active || !over || active.id === over.id || over.data.current?.type !== ReorderTypes.FIELD) return

      const reorderPosition = calculateReorderPosition(event, tableRef)
      if (!reorderPosition) return
      const { isPlacedBefore } = reorderPosition

      const activeIndex = fields.findIndex(field => field.id === active.id)
      const overIndex = fields.findIndex(field => field.id === over.id)

      const isDifferentGroup = fields[overIndex].group !== fields[activeIndex].group

      if (!isDifferentGroup && ((overIndex === activeIndex - 1 && !isPlacedBefore) || (overIndex === activeIndex + 1 && isPlacedBefore))) return // no-op cases

      const targetIndexBase = (isPlacedBefore ? overIndex : overIndex + 1) - (overIndex > activeIndex ? 1 : 0)

      const group = fields[overIndex].group
      const priority = fields[overIndex].priority + (isPlacedBefore ? -0.5 : 0.5)

      const reorderedFields = arrayMove(fields, activeIndex, targetIndexBase)

      // optimistic update

      reorderedFields[targetIndexBase] = { ...reorderedFields[targetIndexBase], group, priority }

      setFields(reorderedFields)

      const isFirstFieldInGroup = reorderedFields.findIndex(field => field.group === group) === targetIndexBase

      reorderField({
        variables: {
          data_point_field_id: active.id as string,
          ...(isFirstFieldInGroup ? { move_to_top_group: group } : { move_after_data_point_field_id: reorderedFields[targetIndexBase - 1].id })
        }
      }) // backend update
    },
    [fields, reorderField]
  )

  const handleGroupDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      setReorderLine(null)

      if (!active || !over || active.id === over.id || over.data.current?.type !== ReorderTypes.GROUP) return

      const reorderPosition = calculateReorderPosition(event, tableRef)
      if (!reorderPosition) return
      const { isPlacedBefore } = reorderPosition

      const activeIndex = groupNames.indexOf(active.id as string)
      const overIndex = groupNames.indexOf(over.id as string)

      if ((overIndex === activeIndex - 1 && !isPlacedBefore) || (overIndex === activeIndex + 1 && isPlacedBefore)) return // no-op cases

      const targetIndexBase = (isPlacedBefore ? overIndex : overIndex + 1) - (overIndex > activeIndex ? 1 : 0)
      const reorderedGroupNames = arrayMove(groupNames, activeIndex, targetIndexBase)

      setGroupNames(reorderedGroupNames) // optimistic update

      reorderGroup({
        variables: {
          group_name: active.id as string,
          move_after_group_name: targetIndexBase ? reorderedGroupNames[targetIndexBase - 1] : null
        }
      }) // backend update
    },
    [groupNames, reorderGroup]
  )

  const handleDragMove = useCallback(
    (event: DragMoveEvent) => {
      const { active, over } = event

      if (!over || active.id === over.id || active.data.current?.type !== over.data.current?.type) {
        setReorderLine(null)
        return
      }

      const reorderPosition = calculateReorderPosition(event, tableRef)
      if (!reorderPosition) return

      const { isPlacedBefore } = reorderPosition

      const activeIndex =
        active.data.current?.type === ReorderTypes.FIELD ? fields.findIndex(field => field.id === active.id) : groupNames.indexOf(active.id as string)
      const overIndex =
        active.data.current?.type === ReorderTypes.FIELD ? fields.findIndex(field => field.id === over.id) : groupNames.indexOf(over.id as string)

      if ((isPlacedBefore && overIndex === activeIndex + 1) || (!isPlacedBefore && overIndex === activeIndex - 1)) {
        setReorderLine(null)
        return
      }

      setReorderLine(reorderPosition.reorderLine)
    },
    [fields, groupNames]
  )

  const context = useMemo(
    () => ({ areMultipleGroups, firstRowRefCallback, hasDeleteFieldPermission, hasReorderPermission, prepareRow, reorderLine, rows, top }),
    [areMultipleGroups, firstRowRefCallback, hasDeleteFieldPermission, hasReorderPermission, prepareRow, reorderLine, rows, top]
  )

  return (
    <Box sx={{ height: `calc(100vh - ${HEADER_HEIGHT + CONFIG_HEADER_HEIGHT}px)`, overflow: 'auto', position: 'relative', width: '100vw' }}>
      <ChecklistTableContext.Provider value={context}>
        {isLoading || isRefetching ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : isEmpty(fieldsData?.cci_deal_data_point_fields) ? (
          <FieldImport onImportSuccess={handleFieldImportSuccess} />
        ) : (
          <DndContext
            collisionDetection={pointerWithin}
            modifiers={[restrictToVerticalAxis]}
            onDragCancel={handleDragCancel}
            onDragEnd={event => (event.active.data.current?.type === ReorderTypes.FIELD ? handleFieldDragEnd : handleGroupDragEnd)(event)}
            onDragMove={handleDragMove}
            onDragStart={handleDragStart}
          >
            <Table {...getTableProps()} ref={tableRef} stickyHeader sx={{ mr: fieldOverlay.isOpen && !fieldOverlay.isClosing ? 105 : 0, width: '100vw' }}>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <HeaderRow headerGroup={headerGroup} key={headerGroup.getHeaderGroupProps().key} />
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {groupNames.map(groupName => (
                  <GroupAndFieldsRows groupName={groupName} key={groupName} />
                ))}
              </TableBody>
            </Table>

            <DragOverlay dropAnimation={null} style={{ zIndex: Z_INDEX_DRAG }}>
              <Box
                sx={{
                  bgcolor: common.white,
                  border: `1px solid ${grey[300]}`,
                  borderRadius: 1,
                  cursor: reorderLine ? 'grabbing' : 'no-drop',
                  px: 1,
                  py: 0.5,
                  position: 'absolute',
                  right: 9,
                  top: 9
                }}
              >
                {dragOverlay}
              </Box>
            </DragOverlay>

            {reorderLine !== null && (
              <Box
                sx={{
                  borderTop: `1px solid ${KLARITY_BLUE}`,
                  left: 0,
                  pointerEvents: 'none',
                  position: 'absolute',
                  top: reorderLine,
                  width: tableRef.current!.clientWidth,
                  zIndex: 100
                }}
              />
            )}
          </DndContext>
        )}

        {fieldOverlay.isOpen && <FieldOverlay />}
      </ChecklistTableContext.Provider>
    </Box>
  )
}

const FieldRow: FC<_FieldRowProps> = ({ fieldId, row }) => {
  const { checkedFields, fieldOverlay } = useCciMainContext()
  const { areMultipleFieldsVisible, isBusy, isReorderingEnabled } = useChecklistConfigContext()
  const { attributes, isDragging, listeners, setNodeRef: setDragRef } = useDraggable({ data: { type: ReorderTypes.FIELD }, id: fieldId })
  const { setNodeRef: setDropRef } = useDroppable({ data: { type: ReorderTypes.FIELD }, id: fieldId })

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      display: 'flex',
      gap: 1,
      justifyContent: 'space-between',
      width: '100%',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -1,
        left: 0,
        right: 0,
        borderTop: `1px solid ${grey[300]}`
      }
    }),
    []
  )

  const iconButtonSx = useMemo(
    () => ({ cursor: isDragging ? 'grabbing' : 'grab', mr: -0.5, my: -1, ':hover': { color: common.black }, ...(isDragging && { color: common.black }) }),
    [isDragging]
  )

  const isFieldOpen = useMemo(() => fieldId === fieldOverlay.fieldId && !fieldOverlay.isClosing, [fieldId, fieldOverlay.fieldId, fieldOverlay.isClosing])
  const isFieldChecked = useMemo(() => checkedFields.includes(fieldId), [checkedFields, fieldId])
  const setRef = useCallback(
    (node: HTMLTableRowElement) => {
      setDragRef(node)
      setDropRef(node)
    },
    [setDragRef, setDropRef]
  )

  return (
    <TableRow
      ref={setRef}
      {...row.getRowProps()}
      sx={{
        bgcolor: isFieldOpen ? KLARITY_BLUE_BACKGROUND : isFieldChecked ? grey[100] : common.white,
        '&:hover': {
          bgcolor: isFieldOpen ? KLARITY_BLUE_BACKGROUND : grey[100]
        }
      }}
    >
      {row.cells.map((cell, index) => {
        const { key, ...props } = cell.getCellProps()

        const showDragHandle = index === size(row.cells) - 1 && areMultipleFieldsVisible && isReorderingEnabled && !row.original.fieldNameColumn.isDeleted

        return (
          <TableCell
            {...props}
            key={key}
            sx={{
              ...(!index
                ? {
                    ...FIRST_CELL_SX,
                    px: 1,
                    bgcolor: isFieldOpen ? KLARITY_BLUE_BACKGROUND : isFieldChecked ? grey[100] : common.white,
                    '.MuiTableRow-root:hover &': { bgcolor: isFieldOpen ? KLARITY_BLUE_BACKGROUND : grey[100] }
                  }
                : CELL_SX),
              '&:last-of-type': { borderRight: 'none' }
            }}
          >
            <Box sx={{ ...boxSx, ...(showDragHandle && { flexDirection: 'row-reverse' }) }}>
              {showDragHandle && (
                <IconButton disabled={isBusy} onClick={event => event.stopPropagation()} size="small" sx={iconButtonSx} {...attributes} {...listeners}>
                  <DragIndicatorIcon />
                </IconButton>
              )}

              {cell.render('Cell')}
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const GroupAndFieldsRows: FC<_GroupAndFieldsRowsProps> = ({ groupName }) => {
  const { filterGroup, filterSearchTerm, isGroupCollapsedMap } = useChecklistConfigContext()
  const { prepareRow, rows, top } = useChecklistTableContext()

  const groupFields = useMemo(() => rows.filter(row => filterGroup(row, groupName)).filter(filterSearchTerm), [rows, groupName, filterGroup, filterSearchTerm])

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      bgcolor: common.white,
      borderBottom: `1px solid ${grey[300]}`,
      borderTop: `1px solid ${grey[300]}`,
      bottom: 0,
      display: 'flex',
      left: 0,
      pl: 6.25,
      position: 'absolute',
      top: -1,
      width: '100vw'
    }),
    []
  )

  const tableCellSx = useMemo(() => ({ ...FIRST_CELL_SX, borderBottom: 0, borderTop: 0, pl: 1, top, zIndex: 3 }), [top])

  return (
    <>
      <GroupRow groupName={groupName} />

      {!isGroupCollapsedMap[groupName] &&
        (isEmpty(groupFields) ? (
          <TableRow>
            <TableCell sx={tableCellSx}>
              <Box sx={boxSx}>
                <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>No fields found.</Typography>
              </Box>
            </TableCell>
          </TableRow>
        ) : (
          <>
            {groupFields.map(row => {
              prepareRow(row)

              return <FieldRow fieldId={row.original.id} key={row.getRowProps().key} row={row} />
            })}
          </>
        ))}
    </>
  )
}

const GroupRow: FC<_GroupRowProps> = ({ groupName }) => {
  const { filterGroup, isBusy, isGroupCollapsedMap, isReorderingEnabled, setIsGroupCollapsedMap } = useChecklistConfigContext()
  const { areMultipleGroups, hasDeleteFieldPermission, hasReorderPermission, rows, top } = useChecklistTableContext()
  const { checkedFields, setCheckedFields } = useCciMainContext()
  const { attributes, isDragging, listeners, setNodeRef: setDragRef } = useDraggable({ data: { type: ReorderTypes.GROUP }, id: groupName })
  const { setNodeRef: setDropRef } = useDroppable({ data: { type: ReorderTypes.GROUP }, id: groupName })

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      bgcolor: common.white,
      borderBottom: `1px solid ${grey[300]}`,
      borderTop: `1px solid ${grey[300]}`,
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      left: 0,
      pl: 1,
      position: 'absolute',
      top: -1,
      width: '100vw',
      ...(isDragging && { cursor: 'grabbing' })
    }),
    [isDragging]
  )

  const dragHandleIconButtonSx = useMemo(
    () => ({ cursor: isDragging ? 'grabbing' : 'grab', mr: 0.5, ':hover': { color: common.black }, ...(isDragging && { color: common.black }) }),
    [isDragging]
  )

  const groupFieldIds = useMemo(
    () => rows.reduce<string[]>((acc, row) => (filterGroup(row, groupName) && !row.original.fieldNameColumn.isDeleted ? [...acc, row.original.id] : acc), []),
    [filterGroup, groupName, rows]
  )

  const checkboxState = useMemo(() => {
    const checkedGroupFieldsCount = size(groupFieldIds.filter(id => checkedFields.includes(id)))

    return checkedGroupFieldsCount === 0
      ? CheckboxStates.UNCHECKED
      : checkedGroupFieldsCount === size(groupFieldIds)
      ? CheckboxStates.CHECKED
      : CheckboxStates.INDETERMINATE
  }, [checkedFields, groupFieldIds])

  const isGroupCollapsed = useMemo(() => isGroupCollapsedMap[groupName], [groupName, isGroupCollapsedMap])

  const expandMoreIconButtonSx = useMemo(
    () => ({
      ':hover': { color: common.black },
      '& .MuiSvgIcon-root': {
        transform: `rotate(${isGroupCollapsed ? '-90' : '0'}deg)`,
        transition: 'transform 0.1s'
      }
    }),
    [isGroupCollapsed]
  )

  const tableCellSx = useMemo(() => ({ ...FIRST_CELL_SX, borderBottom: 0, borderTop: 0, height: 55, pl: 1, top, zIndex: 3 }), [top])

  const checkAllGroupFieldsToggle = useCallback(() => {
    if (checkboxState === CheckboxStates.CHECKED) {
      setCheckedFields(checkedFields.filter(id => !groupFieldIds.includes(id)))
    } else {
      setCheckedFields([...checkedFields, ...groupFieldIds.filter(id => !checkedFields.includes(id))])
    }
  }, [checkboxState, checkedFields, groupFieldIds, setCheckedFields])

  const setRef = useCallback(
    (node: HTMLTableRowElement) => {
      setDragRef(node)
      setDropRef(node)
    },
    [setDragRef, setDropRef]
  )

  const toggleGroupCollapse = useCallback(
    (groupName: string) => setIsGroupCollapsedMap(previous => ({ ...previous, [groupName]: !previous[groupName] })),
    [setIsGroupCollapsedMap]
  )

  return (
    <TableRow ref={setRef}>
      <TableCell sx={tableCellSx}>
        <Box sx={boxSx}>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            {(hasDeleteFieldPermission || hasReorderPermission) && (
              <Checkbox
                checked={checkboxState === CheckboxStates.CHECKED}
                indeterminate={checkboxState === CheckboxStates.INDETERMINATE}
                onClick={checkAllGroupFieldsToggle}
                size="small"
                sx={CHECKBOX_SX}
              />
            )}

            <Typography sx={{ fontSize: 16, fontWeight: 600, ml: 1, whiteSpace: 'nowrap' }}>{groupName}</Typography>

            <IconButton disabled={isBusy} onClick={() => toggleGroupCollapse(groupName)} size="small" sx={expandMoreIconButtonSx}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>

          {areMultipleGroups && isReorderingEnabled && (
            <IconButton disabled={isBusy} onClick={event => event.stopPropagation()} size="small" sx={dragHandleIconButtonSx} {...attributes} {...listeners}>
              <DragIndicatorIcon />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

const HeaderRow: FC<_HeaderRowProps> = ({ headerGroup }) => {
  const { checkedFields, setCheckedFields } = useCciMainContext()
  const { firstRowRefCallback, hasDeleteFieldPermission, hasReorderPermission, rows } = useChecklistTableContext()
  const { areAllGroupsCollapsed, isBusy, setIsGroupCollapsedMap } = useChecklistConfigContext()

  const checkboxState = useMemo(() => {
    const nonDeletedRows = rows.filter(row => !row.original.fieldNameColumn.isDeleted)
    const checkedNonDeletedFieldsCount = size(checkedFields.filter(id => nonDeletedRows.some(row => row.original.id === id)))

    return checkedNonDeletedFieldsCount === 0
      ? CheckboxStates.UNCHECKED
      : checkedNonDeletedFieldsCount === size(nonDeletedRows)
      ? CheckboxStates.CHECKED
      : CheckboxStates.INDETERMINATE
  }, [checkedFields, rows])

  const tableCellSx = useMemo(
    () => ({ bgcolor: grey[100], position: 'sticky', py: 0, top: 0, whiteSpace: 'nowrap', '&:last-of-type': { borderRight: 'none' } }),
    []
  )

  const checkAllFieldsToggle = useCallback(() => {
    if (checkboxState === CheckboxStates.CHECKED) {
      setCheckedFields([])
    } else {
      setCheckedFields(rows.reduce<string[]>((acc, row) => (row.original.fieldNameColumn.isDeleted ? acc : [...acc, row.original.id]), []))
    }
  }, [checkboxState, rows, setCheckedFields])

  const expandAllGroupsToggle = useCallback(() => {
    setIsGroupCollapsedMap(previous => {
      const isCollapsing = Object.values(previous).some(value => !value)

      return Object.fromEntries(Object.keys(previous).map(key => [key, isCollapsing]))
    })
  }, [setIsGroupCollapsedMap])

  return (
    <TableRow {...headerGroup.getHeaderGroupProps()} ref={firstRowRefCallback}>
      {headerGroup.headers.map((column, index) => {
        const { key, ...props } = column.getHeaderProps()

        return (
          <TableCell
            {...props}
            key={key}
            sx={{
              ...(!index ? { ...FIRST_CELL_SX, maxWidth: 420, minWidth: 420, pl: 1, pr: 0.5, zIndex: 3, width: 420 } : CELL_SX),
              ...tableCellSx,
              ...(SAMPLES_COLUMNS.includes(column.Header as string) && { bgcolor: grey[200], width: 10 }),
              ...(column.Header === SAMPLES_COLUMNS[0] && { fontWeight: 600 })
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1, ...(!index && !hasDeleteFieldPermission && !hasReorderPermission && { ml: 1 }) }}>
              {!index && (hasDeleteFieldPermission || hasReorderPermission) && (
                <Checkbox
                  checked={checkboxState === CheckboxStates.CHECKED}
                  color="primary"
                  indeterminate={checkboxState === CheckboxStates.INDETERMINATE}
                  onClick={checkAllFieldsToggle}
                  size="small"
                  sx={CHECKBOX_SX}
                />
              )}

              <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {column.render('Header')}

                {!index && (
                  <IconButton disabled={isBusy} onClick={expandAllGroupsToggle} size="small" sx={{ ':hover': { color: common.black } }}>
                    {areAllGroupsCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                  </IconButton>
                )}
              </Box>
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}
