import { Box, Typography } from '@mui/material'
import { OnOffLabels } from '../../../../../utils/cci'
import React, { FC } from 'react'

// types

type _DefaultColumnCellProps = { defaultFieldOnDashboard: boolean }

// components

export const DefaultColumnCell: FC<_DefaultColumnCellProps> = ({ defaultFieldOnDashboard }) => (
  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
    <Typography variant="body2">{defaultFieldOnDashboard ? OnOffLabels.ON : OnOffLabels.OFF}</Typography>
  </Box>
)
