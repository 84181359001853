import { Box, IconButton, Typography } from '@mui/material'
import { COLOR_DANGER_5 } from '../../../../../../../utils/styleUtils'
import { DataPointFieldMentions } from '../../../../../../../components/DataPointFieldMentions'
import { FiXSquare } from 'react-icons/fi'
import React, { FC } from 'react'

// types

type _ExtractionDescriptionInputProps = { currentValue?: string; handleData: (key: string, value: string | undefined) => void; isDisabled?: boolean }

// constants

const FIELD = 'extraction_description'
const ID = 'extraction-description-input'
const LABEL = 'Extraction Description'

// components

export const ExtractionDescriptionInput: FC<_ExtractionDescriptionInputProps> = ({ currentValue = '', handleData, isDisabled }) => (
  <Box sx={{ alignItems: 'baseline', display: 'flex', my: 1 }}>
    <Typography component="label" htmlFor={ID} sx={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between', mr: 2, minWidth: 164 }} variant="body2">
      {LABEL}
    </Typography>

    <Box sx={{ alignItems: 'center', display: 'flex', flex: 1 }}>
      <DataPointFieldMentions
        id={ID}
        isDisabled={isDisabled}
        label={LABEL}
        onChange={(event: { target: { value: string } }) => handleData(FIELD, event.target.value)}
        value={currentValue}
      />

      <IconButton
        disabled={!currentValue || isDisabled}
        onClick={() => handleData(FIELD, undefined)}
        size="small"
        sx={{ borderRadius: 0.5, color: COLOR_DANGER_5, fontSize: 20, height: 28, ml: 1, mt: 0.25, padding: 0.5, width: 28 }}
      >
        <FiXSquare />
      </IconButton>
    </Box>
  </Box>
)
