import { Typography } from '@mui/material'
import { formatFieldSemantics } from '../../../../../utils/commentUtils'
import React, { FC } from 'react'

// types

type _DescriptionCellProps = { description: string }

// components

export const DescriptionCell: FC<_DescriptionCellProps> = ({ description }) => (
  <Typography sx={{ fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{formatFieldSemantics(description)}</Typography>
)
