import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material'
import { DOCUMENT_SELECTOR_HEIGHT } from '../DocumentSelector'
import { DataPointDataTable, TableCompositionConfigEdge } from '../../../graphql/codegen/schemas'
import { ExportToCsv } from 'export-to-csv'
import { Resizable } from '../../Resizable'
import { SpreadsheetEditor, _Column, _Row, _SpreadsheetEditorRef } from './SpreadsheetEditor'
import { Toast } from '../../Toast'
import { Z_INDEX_OVERLAY } from '../../../utils/styleUtils'
import { captureError } from '../../../utils/sentry'
import { isEmpty, size } from 'lodash'
import { useAppContext } from '../../../app'
import { useDataPointsQuery, useEditDataTableDataPointValueMutation } from '../../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import Loader from '../../Loader'
import MinimizeIcon from '@mui/icons-material/Minimize'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import copy from 'copy-to-clipboard'
import usePromptOnUnmount from '../../../hooks/usePromptOnUnmount'

// types

type _CopyTableProps = { color?: 'default' | 'inherit'; handleCopy: () => void }

type _DataTableSpreadsheetProps = { dealIsFinalized: boolean; setActiveTableId: Dispatch<SetStateAction<string | null>>; tableId: string }

type _DownloadCsvProps = { color?: 'default' | 'inherit'; handleDownload: () => void }

type _ExpandViewProps = { color?: 'default' | 'inherit'; handleToggleExpand: () => void }

// enums

enum ValidationErrorType {
  Duplicate = 'duplicate',
  Extra = 'extra',
  Missing = 'missing',
  NoHeader = 'noHeader'
}

// functions

const copyDataTable = (dataTable: DataPointDataTable, setToast: Dispatch<SetStateAction<string>>) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  let counter = 1

  copy(
    [
      ...(columns ? [columns.map(column => column || `<Untitled ${counter++}>`)] : []),
      ...(columns ? rows : [rows[0].map(row => row || `<Untitled ${counter++}>`), ...rows.slice(1)])
    ]
      .map(row => row.join('\t'))
      .join('\n'),
    {
      format: 'text/plain'
    }
  )

  setToast('Table copied to clipboard.')
}

const downloadDataTableCsvFile = (dataTable: DataPointDataTable) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  let counter = 1

  const options = {
    filename: 'table',
    ...(columns && { headers: columns.map(column => `"${column || `<Untitled ${counter++}>`}"`) }),
    showLabels: true
  }

  new ExportToCsv(options).generateCsv(columns ? rows : [rows[0].map(row => row || `<Untitled ${counter++}>`), ...rows.slice(1)])
}

const getDataTableColumnsAndRows = (dataTable: DataPointDataTable): [string[] | undefined, string[][]] => {
  const columns = dataTable.columns as string[] | undefined
  const rows = (JSON.parse(dataTable.rows!) as string[]).map(row => Object.values(row))

  return [columns, rows]
}

const getTableCompositionConfigColumns = (tableCompositionConfig: TableCompositionConfigEdge[]): _Column[] =>
  tableCompositionConfig
    .filter((edge): edge is NonNullable<typeof edge> => edge !== null)
    .map(edge => ({ name: edge.node?.column_name || '', type: edge.node?.column_type }))
    .filter(column => column.name !== '')

const getValidationError = (type: ValidationErrorType, columns: string[] | number[]) => {
  const count = size(columns)
  const isSingular = count === 1

  const messages = {
    duplicate: {
      columns: columns.map(column => `"${column}"`),
      prefix: `Duplicate column name${isSingular ? '' : 's'} detected:`,
      suffix: 'Please ensure all column names are unique before saving.'
    },
    extra: {
      columns: columns.map(column => `"${column}"`),
      prefix: `Extra column${isSingular ? '' : 's'} detected:`,
      suffix: `Please remove ${isSingular ? 'this column' : 'these columns'} before saving.`
    },
    missing: {
      columns: columns.map(column => `"${column}"`),
      prefix: `Missing required column${isSingular ? '' : 's'}:`,
      suffix: `Please add ${isSingular ? 'this column' : 'these columns'} before saving.`
    },
    noHeader: {
      columns: (columns as number[]).map(column => `"${String.fromCharCode(65 + column)}"`),
      prefix: `Column${isSingular ? '' : 's'} without headers detected:`,
      suffix: `Please add ${isSingular ? 'a column header' : 'column headers'} before saving.`
    }
  }

  const { columns: formattedColumns, prefix, suffix } = messages[type]

  return `${prefix} ${formattedColumns.join(', ')}. ${suffix}`
}

const mergeColumnData = (dataTableColumns: _Column[], tableCompositionConfigColumns: _Column[]): _Column[] =>
  dataTableColumns.map(dataTableColumn => {
    const tableCompositionColumn = tableCompositionConfigColumns.find(({ name }) => name.trim() === dataTableColumn.name.trim())

    // Add `type` to `dataTableColumn` if it exists in `tableCompositionConfigColumns`.
    return tableCompositionColumn ? { ...dataTableColumn, type: tableCompositionColumn.type } : dataTableColumn
  })

const validateSpreadsheetData = (
  newColumns: _Column[],
  initialColumns: _Column[],
  emptyColumnHeaderIndices: number[],
  hasTableCompositionConfig: boolean,
  setValidationError: (error: string) => void
): boolean => {
  const newColumnNameList = newColumns.map(({ name }) => name)
  const initialColumnNameList = initialColumns.map(({ name }) => name)

  const newColumnNameSet = new Set(newColumnNameList)
  const initialColumnNameSet = new Set(initialColumnNameList)

  const duplicateColumns = newColumnNameList.filter((columnName, index) =>
    newColumnNameList.slice(0, index).some(_columnName => _columnName.toLowerCase() === columnName.toLowerCase())
  )
  const missingTableCompositionColumns = initialColumnNameList.filter(columnName => !newColumnNameSet.has(columnName))
  const extraColumns = newColumnNameList.filter(columnName => !initialColumnNameSet.has(columnName))

  let validationError = ''

  if (!isEmpty(emptyColumnHeaderIndices)) {
    validationError = getValidationError(ValidationErrorType.NoHeader, emptyColumnHeaderIndices)
  }

  if (!isEmpty(duplicateColumns)) {
    validationError = getValidationError(ValidationErrorType.Duplicate, duplicateColumns)
  }

  if (hasTableCompositionConfig) {
    if (!isEmpty(missingTableCompositionColumns)) {
      validationError = getValidationError(ValidationErrorType.Missing, missingTableCompositionColumns)
    }

    if (!isEmpty(extraColumns)) {
      validationError = getValidationError(ValidationErrorType.Extra, extraColumns)
    }
  }

  setValidationError(validationError)

  return !validationError
}

// components

const CopyTable: FC<_CopyTableProps> = ({ color = 'default', handleCopy }) => (
  <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Copy table">
    <IconButton color={color} onClick={handleCopy} size="small">
      <ContentCopyIcon />
    </IconButton>
  </Tooltip>
)

export const DataTableSpreadsheet: FC<_DataTableSpreadsheetProps> = ({ dealIsFinalized, setActiveTableId, tableId }) => {
  const { dealId } = useParams<{ dealId?: string }>()
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  const [absoluteHeight, setAbsoluteHeight] = useState(512)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [initialColumns, setInitialColumns] = useState<_Column[]>([])
  const [initialRows, setInitialRows] = useState<_Row[] | undefined>(undefined)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isRefetching, setIsRefetching] = useState(false)
  const [toast, setToast] = useState('')
  const [validationError, setValidationError] = useState<string>('')

  const spreadsheetRef = useRef<_SpreadsheetEditorRef>(null)

  const {
    data,
    loading: areDataPointsLoading,
    refetch
  } = useDataPointsQuery({
    variables: { id: tableId },
    onCompleted: ({ data_points }) => {
      const dataPoint = data_points?.edges[0]?.node
      const dataTable = dataPoint?.value_data_table
      const tableCompositionConfig = (dataPoint?.data_point_field?.table_composition_config?.edges || []) as TableCompositionConfigEdge[]
      const tableCompositionConfigColumns = getTableCompositionConfigColumns(tableCompositionConfig)

      if (isEmpty(tableCompositionConfigColumns) && isEmpty(dataTable?.columns)) {
        setInitialColumns([])
        setInitialRows([])

        return
      }

      // If `dataTable` exists, use its columns as the base and append any missing `tableCompositionConfig` columns.
      if (dataTable?.columns && dataTable?.rows) {
        try {
          const dataTableColumns = (dataTable.columns as string[]).map(name => ({ name }))

          // Find columns that are in `tableCompositionConfigColumns` but not in `dataTableColumns`.
          const missingTableCompositionColumns = tableCompositionConfigColumns.filter(
            tableCompositionColumn => !dataTableColumns.some(dataTableColumn => dataTableColumn.name.trim() === tableCompositionColumn.name.trim())
          )

          if (!initialRows || !hasUnsavedChanges) {
            const dataTableColumnsWithTypes = mergeColumnData(dataTableColumns, tableCompositionConfigColumns)

            setInitialColumns([...dataTableColumnsWithTypes, ...missingTableCompositionColumns])
            setInitialRows(JSON.parse(dataTable.rows))
          }

          return
        } catch (error) {
          captureError(error)
        }
      }

      // If `dataTable` is null or parsing failed, use allowed columns from `tableCompositionConfig`.
      if (!initialRows || !hasUnsavedChanges) {
        setInitialColumns(tableCompositionConfigColumns)
        setInitialRows([])
      }
    }
  })

  const [editDataTableDataPointValue, { loading: isLoading }] = useEditDataTableDataPointValueMutation({
    onCompleted: data => {
      setToast('Table saved.')
      setHasUnsavedChanges(false)

      const { value_data_table: valueDataTable } = data.edit_data_table_data_point_value?.data_points?.[0] || {}

      if (valueDataTable && spreadsheetRef.current) {
        const columns = (valueDataTable.columns as string[]).map(name => ({ name }))
        const rows = JSON.parse(valueDataTable.rows!)

        spreadsheetRef.current.initializeSheet(columns, rows)

        setInitialColumns(columns)
        setInitialRows(rows)
      }
    }
  })

  const dataTable = useMemo(() => data?.data_points?.edges[0]?.node?.value_data_table, [data?.data_points?.edges])

  const tableCompositionConfig = useMemo(
    () => (data?.data_points?.edges[0]?.node?.data_point_field?.table_composition_config?.edges || []) as TableCompositionConfigEdge[],
    [data?.data_points?.edges]
  )

  const tableCompositionConfigColumns = useMemo(() => getTableCompositionConfigColumns(tableCompositionConfig), [tableCompositionConfig])

  const columns = useMemo<_Column[]>(() => {
    if (dataTable?.columns) {
      const dataTableColumns = (dataTable.columns as string[]).map(name => ({ name }))

      if (!isEmpty(tableCompositionConfigColumns)) {
        const missingTableCompositionColumns = tableCompositionConfigColumns.filter(
          tableCompositionColumn => !dataTableColumns.some(({ name }) => name.trim() === tableCompositionColumn.name.trim())
        )

        const dataTableColumnsWithTypes = mergeColumnData(dataTableColumns, tableCompositionConfigColumns)

        return [...dataTableColumnsWithTypes, ...missingTableCompositionColumns]
      }

      return dataTableColumns
    }

    if (!isEmpty(tableCompositionConfigColumns)) return tableCompositionConfigColumns

    return []
  }, [dataTable?.columns, tableCompositionConfigColumns])

  const rows = useMemo(() => {
    const rowData = dataTable?.rows

    if (!rowData) return initialRows

    try {
      return JSON.parse(rowData)
    } catch (error) {
      captureError(error)
      return initialRows
    }
  }, [dataTable?.rows, initialRows])

  // functions

  const handleCopy = useCallback(() => {
    if (dataTable) copyDataTable(dataTable, setToast)
  }, [dataTable])

  const handleDownload = useCallback(() => {
    if (dataTable) downloadDataTableCsvFile(dataTable)
  }, [dataTable])

  const handleRevert = useCallback(() => {
    if (!spreadsheetRef.current || !initialRows) return

    const workbook = spreadsheetRef.current.getWorkbook()

    if (!workbook) return

    spreadsheetRef.current.initializeSheet(initialColumns, initialRows)

    setHasUnsavedChanges(false)
  }, [initialColumns, initialRows])

  const handleSave = useCallback(() => {
    if (!hasUnsavedChanges || !spreadsheetRef.current) return

    const spreadsheetData = spreadsheetRef.current.getSpreadsheetData()

    if (!spreadsheetData) return

    const { columns: newColumns, emptyColumnHeaderIndices, rows: newRows } = spreadsheetData

    const hasTableCompositionConfig = !isEmpty(tableCompositionConfigColumns)

    const isSpreadsheetDataValid = validateSpreadsheetData(newColumns, initialColumns, emptyColumnHeaderIndices, hasTableCompositionConfig, setValidationError)

    if (!isSpreadsheetDataValid) return

    setValidationError('')

    const dataPointFieldId = data?.data_points?.edges[0]?.node?.data_point_field?.id!
    const documentId = data?.data_points?.edges[0]?.node?.document?.id || ''

    editDataTableDataPointValue({
      onError: error => {
        setErrorMessage(error.message === 'Failed to fetch' ? 'Failed to save table changes.' : error.message)
        setExtendedErrorMessage(error.stack || error.message)
      },
      variables: {
        dataPointFieldId,
        dealId: documentId ? '' : dealId!,
        documentId,
        tableColumns: newColumns.map(({ name }) => name),
        tableRows: JSON.stringify(newRows)
      }
    })
  }, [
    data?.data_points?.edges,
    dealId,
    editDataTableDataPointValue,
    hasUnsavedChanges,
    initialColumns,
    setErrorMessage,
    setExtendedErrorMessage,
    tableCompositionConfigColumns
  ])

  const handleSpreadsheetChange = useCallback(() => {
    if (!dealIsFinalized) {
      const spreadsheetData = spreadsheetRef.current?.getSpreadsheetData()

      if (spreadsheetData) {
        setHasUnsavedChanges(!spreadsheetData.isUnchanged)

        setValidationError('')
      }
    }
  }, [dealIsFinalized])

  const handleTableClose = useCallback(() => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes in this table. Are you sure you want to close it? Click "OK" to close or "Cancel" to stay on this page.')) {
        setActiveTableId(null)
      }
    } else {
      setActiveTableId(null)
    }
  }, [hasUnsavedChanges, setActiveTableId])

  const handleToggleExpand = useCallback(() => {
    const activeElement = document.activeElement as HTMLElement

    activeElement?.blur()

    setIsExpanded(previous => !previous)

    setTimeout(() => spreadsheetRef.current?.getWorkbook()?.refresh())
  }, [])

  // effects

  usePromptOnUnmount(hasUnsavedChanges, 'You have unsaved changes in this table. Are you sure you want to leave? Click "Cancel" to stay on this page.')

  useEffect(() => {
    setHasUnsavedChanges(false)

    setValidationError('')

    setIsRefetching(true)

    refetch().finally(() => setIsRefetching(false))
  }, [refetch, tableId])

  useEffect(() => {
    const handleTableSwitch = (_event: Event) => {
      const event = _event as CustomEvent<{ nextTableId: string }>

      if (hasUnsavedChanges) {
        const proceed = window.confirm(
          'You have unsaved changes in this table. Are you sure you want to switch tables? Click "OK" to switch or "Cancel" to stay on this page.'
        )

        if (!proceed) event.preventDefault()
      }
    }

    window.addEventListener('checkUnsavedTableChanges', handleTableSwitch)

    return () => window.removeEventListener('checkUnsavedTableChanges', handleTableSwitch)
  }, [hasUnsavedChanges])

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (isExpanded && event.key === 'Escape') handleToggleExpand()
    }

    document.addEventListener('keydown', handleEscapeKey)

    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [isExpanded, handleToggleExpand])

  useEffect(() => {
    const mainElement = document.querySelector('main')

    // This hack ensures that the expanded table is rendered on top of the NavBar (which is rendered outside of the <main> element).
    isExpanded ? mainElement?.style.setProperty('z-index', Z_INDEX_OVERLAY.toString()) : mainElement?.style.removeProperty('z-index')

    return () => {
      mainElement?.style.removeProperty('z-index')
    }
  }, [isExpanded])

  // render

  return (
    <>
      <Resizable
        height={absoluteHeight}
        isHandleHidden={isExpanded}
        onResize={() => spreadsheetRef.current?.getWorkbook()?.refresh()}
        setHeight={setAbsoluteHeight}
        sx={isExpanded ? { inset: 0, position: 'fixed', zIndex: Z_INDEX_OVERLAY } : undefined}
      >
        <Box sx={{ height: isExpanded ? '100vh' : absoluteHeight, pb: Boolean(dealId) && !isExpanded ? `${DOCUMENT_SELECTOR_HEIGHT}px` : undefined }}>
          {areDataPointsLoading || isRefetching ? (
            <Loader />
          ) : (
            <>
              {validationError && (
                <Alert
                  onClose={() => setValidationError('')}
                  severity="warning"
                  sx={{ borderRadius: 0, left: 0, padding: '3px 16px', position: 'absolute', top: '4px', width: 'calc(100% - 230px)', zIndex: Z_INDEX_OVERLAY }}
                >
                  {validationError}
                </Alert>
              )}

              <Box sx={{ alignItems: 'center', bgcolor: '#f3f4f8', display: 'flex', gap: 0.5, justifyContent: 'space-between', p: '8px 4px 4px' }}>
                {Boolean(dataTable) && (
                  <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5, ml: 0.5 }}>
                    {isExpanded && (
                      <Typography noWrap sx={{ maxWidth: '60vw', mr: 1 }} variant="body1">
                        {data?.data_points?.edges[0]?.node?.data_point_field?.name}
                      </Typography>
                    )}

                    <CopyTable handleCopy={handleCopy} />

                    <DownloadCsv handleDownload={handleDownload} />

                    {!isExpanded && <ExpandView handleToggleExpand={handleToggleExpand} />}
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexShrink: 0, gap: 0.5, mr: 0.5 }}>
                  {!dealIsFinalized && (
                    <>
                      <Button color="inherit" disabled={!hasUnsavedChanges || isLoading} onClick={handleRevert} size="small" variant="outlined">
                        Revert
                      </Button>

                      <Button color="primary" disabled={!hasUnsavedChanges || isLoading} onClick={handleSave} size="small" variant="contained">
                        Save Changes
                      </Button>
                    </>
                  )}

                  <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title={isExpanded ? 'Minimize' : 'Close table'}>
                    <IconButton onClick={isExpanded ? handleToggleExpand : handleTableClose} size="small">
                      {isExpanded ? <MinimizeIcon /> : <CloseIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <SpreadsheetEditor
                columns={columns}
                id={tableId}
                isReadOnly={dealIsFinalized}
                onChange={handleSpreadsheetChange}
                ref={spreadsheetRef}
                rows={rows}
              />
            </>
          )}
        </Box>
      </Resizable>

      {/* See: https://github.com/mui/material-ui/issues/43106#issuecomment-2314809028 */}
      <Dialog closeAfterTransition={false} disableEscapeKeyDown open={isLoading} sx={{ zIndex: Z_INDEX_OVERLAY }}>
        <DialogContent sx={{ alignItems: 'center', display: 'flex', gap: 3, p: 6 }}>
          <CircularProgress size={24} />

          <Typography variant="body1">Saving table…</Typography>
        </DialogContent>
      </Dialog>

      {toast && <Toast message={toast} setMessage={setToast} />}
    </>
  )
}

const DownloadCsv: FC<_DownloadCsvProps> = ({ color = 'default', handleDownload }) => (
  <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Download CSV">
    <IconButton color={color} onClick={handleDownload} size="small">
      <FileDownloadOutlinedIcon />
    </IconButton>
  </Tooltip>
)

const ExpandView: FC<_ExpandViewProps> = ({ color = 'default', handleToggleExpand }) => (
  <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Expand view">
    <IconButton color={color} onClick={handleToggleExpand} size="small">
      <FullscreenIcon />
    </IconButton>
  </Tooltip>
)
